<app-top-menu-two *ngIf="this.showTopMenu"></app-top-menu-two>
<div class="my-content">
  <div class="my-main">
    <div class="navegation-header" style="border-bottom: 1px solid #d3d3d3">
      <div class="navegation-header__icon" (click)="goback()">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </div>
      <div class="navegation-header__tittle">
        <h3>Meu Consumo</h3>
        <!-- <i class="fa-solid fa-arrows-rotate refresh-view" [ngClass]="{'clicked': this.clicked}" (click)="refreshPage()"></i> -->
      </div>
    </div>

    <ng-container >
      <div style="padding-top: 55px; display: flex; justify-content: space-around; align-items: center;">

        <div class="header-info" *ngIf="alterationString">
          <span style=" font-weight: 600; color: #636363;;">{{ this.nameIdentification }}</span>
          <i *ngIf="alterationString" style="display: flex; align-items: center; justify-content: center;"><img src="assets/icons/card-1.png" style="width: 35px; z-index: 99;"/><span class="number-param-command">{{ this.typeParam.numberParam }}</span></i>
        </div>

        <div class="header-info" *ngIf="!alterationString" style="gap: 7px;">
          <i *ngIf="!alterationString"><img src="assets/restaurante.png" style="width: 35px;" /></i>
          <span style="font-weight: 600; color: #636363;">{{ this.nameIdentification }}</span><span style=" font-weight: 600; color: #636363;">{{ this.typeParam.numberParam }}</span>
        </div>

        <div *ngIf="this.tableNumberSelected != '' && this.typeParam.type == 2" class="number-table" (click)="showModalAlterationTable()">
          <span style="color: #636363;;">MESA {{ this.tableNumberSelected }}</span>
          <button style="border: none; background-color: white; margin-left: 5px;" ><i [ngStyle]="{'color': !this.permissionRequest ? 'gray' : 'var(--primary-color)'}" class="fa-regular fa-pen-to-square" style="font-size: 25px;"></i></button>
        </div>
      </div>
      <div class="name-customer" *ngIf="this.nameCustomer != ''" (click)="modalNameAlterarion()">
        <span style="margin-right: 5px;">{{ this.nameCustomer }}</span>
        <button style="border: none; background-color: white;"><i [ngStyle]="{'color': !this.permissionRequest ? 'gray' : 'var(--primary-color)'}" class="fa-regular fa-pen-to-square" style=" font-size: 20px;"></i></button>
      </div>

      <hr style="color: #ada9a9; margin: 7px auto; width: calc(100% - 2rem); margin-bottom: 3px; margin-top: 3px;"/>
      <div class="box" style="padding-bottom: 190px; padding-top: 0;" *ngIf="order && order.items && order.items.length > 0">
        <!-- <div class="box-tittle">
          <div>Consumo</div>
        </div> -->
        <ng-container *ngIf="order && order.items">
        <div class="order" *ngFor="let item of this.order.items; let i = index">
          <div class="order-content">
            <div class="order-content__info">
              <!-- <div class="picture-prod">
                <img [src]="item.product.image_prod" style="width: 35px; height: 35px;"/>
              </div> -->
              <div class="order-content__info__details">
                
                <div  *ngIf="item.observations == ''"  style="width: 100%; display: flex; flex-direction: column;">
                  <div style="display: flex; justify-content: space-between;">
                    <div>
                      <div>
                        <span style="font-size: 14px; padding-right: 4px; font-weight: 800;">{{ item.quantity }}x</span>
                        <span style="font-size: 14px;  font-weight: 800;">{{ item.product.name }}</span>
                      </div>
                      <div [appTimeFormat]="item.createdAtItem" style="color: gray; font-size: 11px;">{{ item.createdAtItem }}</div>

                    </div>
                    <div class="value-time">
                      <div class="info__detail__price">{{ item.totalPrice | currency:'BRL' }}</div>

                      <!-- <div [appTimeFormat]="item.createdAtItem" >{{ item.createdAtItem }}</div> -->
                    </div>
                  </div>
                  <div class="order-item__additional" >
                    <div class="cart-item__category" >
                      <ng-container *ngFor="let additionais of item.additional">
                        <ng-container *ngIf="additionais.product.flavor == 1">
                          <ng-container *ngIf="item.additional.length == 1">
                            <div style="line-height: 1;">
                              <span style="font-size: 13px; color: #5d5d5d;">1x</span><span style="font-size: 12px;"> {{ additionais.product.name }}</span>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="item.additional.length != 1">
                            <div style="line-height: 1;">
                              <span style="font-size: 13px; color: #5d5d5d;">{{ additionais.quantitySub }}/{{ item.flavorsCount }}</span><span style="font-size: 12px;"> {{ additionais.product.name }}</span>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                      <ng-container *ngFor="let additionais of item.additional">
                        <ng-container *ngIf="additionais.product.flavor == 0">
                          <div style="line-height: 1;">
                            <span style="font-size: 13px; color: #5d5d5d;">{{ additionais.quantitySub }}x </span><span style="font-size: 12px;">{{ additionais.product.name }}</span>
                          </div>
                        </ng-container>
                      </ng-container>
                      <!-- <div class="cart-item__additional__item" *ngFor="let additional of item.additional">
                        <span style="font-weight: 600; font-size: 13px; padding-left: 10px; color: #5d5d5d;">{{ additional.quantitySub }}x </span>
                        <span style="font-size: 12px;color: gray; font-weight: 600;">{{ additional.product.name }}</span>
                      </div> -->
                    </div>
                  </div>
                </div>


                <div  *ngIf="item.observations != ''" style="width: 100%; display: flex; flex-direction: column;">
                  <div style="display: flex; justify-content: space-between;">
                    <div>
                      <div>
                        <span style="font-size: 14px; padding-right: 4px; font-weight: 800;">{{ item.quantity }}x</span>
                        <span style="font-size: 14px;  font-weight: 800;">{{ item.product.name }}</span>
                      </div>
                      <div [appTimeFormat]="item.createdAtItem" style="color: gray; font-size: 11px;">{{ item.createdAtItem }}</div>
                    </div>

                    <div class="value-time">
                      <div class="info__detail__price">{{ item.totalPrice | currency:'BRL' }}</div>
                    </div>
                  </div>
                  <div class="order-item__additional" >
                    <div class="cart-item__category" >
                      <ng-container *ngFor="let additionais of item.additional">
                        <ng-container *ngIf="additionais.product.flavor == 1">
                          <ng-container *ngIf="item.additional.length == 1">
                            <div>
                              <span style="font-size: 13px; color: #5d5d5d;">1x</span><span style="font-size: 12px;"> {{ additionais.product.name }}</span>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="item.additional.length != 1">
                            <div>
                              <span style="font-size: 13px; color: #5d5d5d;">{{ additionais.quantitySub }}/{{ item.flavorsCount }}</span><span style="font-size: 12px;"> {{ additionais.product.name }}</span>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                      <ng-container *ngFor="let additionais of item.additional">
                        <ng-container *ngIf="additionais.product.flavor == 0">
                          <div>
                            <span style="font-size: 13px; color: #5d5d5d;">{{ additionais.quantitySub }}x </span><span style="font-size: 12px;">{{ additionais.product.name }}</span>
                          </div>
                        </ng-container>
                      </ng-container>
                      <!-- <div class="cart-item__additional__item" *ngFor="let additional of item.additional">
                        <span style="font-weight: 600; font-size: 13px; padding-left: 10px; color: #5d5d5d;">{{ additional.quantitySub }}x </span>
                        <span style="font-size: 12px;color: gray; font-weight: 600;">{{ additional.product.name }}</span>
                      </div> -->
                    </div>
                  </div>
                  <div class="im__observation" style="font-size: 13px; color: #939393;">
                    <span>Obs: </span><span>{{ item.observations }}</span>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        </ng-container>
      </div>
    </ng-container>

  </div>
</div>
<div>
<app-card-loading [openCard]="this.requestSucess" [labelPerson]="'Aguarde...'"></app-card-loading>
</div>
<div class="box-button-menu" *ngIf="!this.requestSucess">
  <ng-container *ngIf="this.order && this.order.orderPrice">
    <div class="div-value" [ngClass]="{'pb-10': this.company.configs.accountActive == 0}">
      <div>Total</div>
      <div>{{ this.total | currency:'BRL' }}</div>
    </div>
  </ng-container>
  <div class="box-button-content" *ngIf="this.company.configs.accountActive == 1">
    <!-- <button>Observação</button> -->

    <button class="button-call-garçom" *ngIf="!alterationButtonModalCall && this.permissionRequest" (click)="showModalCallWaiterTable()"> <i class="fa-regular fa-bell bell-animation"></i> Chamar garçom</button>
    <button class="button-call-garçom" *ngIf="alterationButtonModalCall && this.permissionRequest" (click)="showModalCallWaiterCard()"> <i class="fa-regular fa-bell bell-animation"></i> Chamar garçom</button>
    <!-- <button class="button-call-garçom__vibration" [@buttonVibration]="vibrationState" *ngIf="!this.permissionRequest" (click)="vibrateButton()">Chamar garçom</button> -->
    <button class="button-call-garçom disabled-negative vibration" *ngIf="!this.permissionRequest"> <i class="fa-solid fa-bell-slash"></i> Chamar garçom</button>

    <button class="button-call-comand" *ngIf="this.permissionRequest && !this.desabilitButton" (click)="openModalPayment = true;">Pedir a conta</button>
    <button class="button-call-comand disabled-positive" *ngIf="!this.permissionRequest && !this.desabilitButton" (click)="openModalPermissionRequest = true">Pedir a conta</button>

  </div>
</div>
<app-bottom-menu [verification]="this.verification"></app-bottom-menu>


<!--Modal chamar garçom-->
<div class="modal" [ngClass]="{'show': openModalCallTable}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title tittle-modal" id="staticBackdropLabel" style="font-size: 17px !important;">Chamar garçom</h1>
      </div>
      <div class="modal-body" style="padding: 0;">
        <form (ngSubmit)="callWaiter(textObservationWaiter, '0')" style="text-align: center; width: 100%;">
          <div class="observation-area">
            <span style="font-weight: 100;">Motivo da solicitação</span>
            <textarea class="text-area-call" placeholder="Limpar a mesa, trazer talheres, etc." maxlength="48" (input)="receivedEvent($event)" [(ngModel)]="textObservationWaiter" name="textObservationWaiter" #callWatersTable></textarea>
            <div style="display: flex; font-size: 14px; color: #737373;padding-top: 2px;">
              <span class="d-flex" style="width: 20px; justify-content: flex-end;">{{ this.countText }}</span><span>/48</span>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn modal-btn-negative" (click)="openModalCallTable = false;">Cancelar</button>
            <button type="submit" class="btn modal-btn-positive" [disabled]="textObservationWaiter == ''">Chamar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!--Modal Pedir a conta-->
<div class="modal" [ngClass]="{'show': openModalPayment}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h1 class="modal-title fs-5" id="staticBackdropLabel">Pagamento</h1> -->
        <!-- <img src="assets/icons/pay-money.png" style="width: 60px;"/> -->
        <i class="fa-solid fa-hand-holding-dollar" style="font-size: 50px; color: var(--primary-color);"></i>
        <!-- <i class="fa-solid fa-cash-register" style="font-size: 40px;"></i> -->
      </div>
      <form (ngSubmit)="requestBill()">
        <div class="modal-body">
          <!-- <div style="width: 100%; padding: 0 1rem 0 1rem; margin-bottom: 10px;">
            <div class="button-split" (click)="buttonsUsers()">
              <div class="button-split__content">
                <div class="icon-button-split">
                  <i class="fa-solid fa-user-plus" style="color:#652969; font-size: 20px;"></i>
                </div>
                <span>Vamos dividir a conta!</span>
              </div>
            </div>
          </div> -->
          <div class="box" style="width: 100%; padding-top: 0px;">
            <div class="box-tittle" style="justify-content: space-between; align-items: center;">
              <div>Vamos dividir a conta!</div>
              <div style="display: flex; flex-direction: column; align-items: center;">
                <span style="font-size: 10px; color: gray;">*Valor total</span>
                <span>{{ this.total | currency:'BRL' }}</span>
              </div>

            </div>
            <div>
              <div class="people" id="people">
                <div class="box-counter-icon">
                  <div class="icon-content">
                    <i class="fa-solid fa-users" style="color: var(--primary-color); font-size: 20px;"></i>
                  </div>
                  <div class="counter">
                    <button class="button-down" type="button" #btnNegative [ngClass]="{'activated': this.counter > 1, 'disabled': this.counter == 1}">
                      <div class="btn-negative">

                        <!-- <div (click)="removeOneInCounter(item)">-</div> -->
                        <div (click)="removeInCounter()">-</div>
                      </div>
                    </button>
                    <div class="counter-div">{{ this.counter }}</div>
                    <button class="button-plus" type="button">
                      <div class="btn-positive">
                        <!-- <div (click)="addOneInCounter(item)">+</div> -->
                        <div (click)="addInCounter()">+</div>
                      </div>
                    </button>
                  </div>
                </div>
                <div>
                  <span style="font-size: 18px; font-weight: 600;">{{ this.totalDivision | currency:'BRL' }}</span>
                </div>

              </div>
            </div>
          </div>

          <div class="box-payment">
            <div class="box-tittle">
              <div>Formas de pagamento</div>
            </div>
            <ng-container>
            <div class="form-payment">
              <ng-container *ngFor="let method of this.company.company.paymentForms">
                <div class="d-flex align-items-center box-checkRadio-payment">
                  <div class="line-form-payment">
                    <ng-container [ngSwitch]="method.icon">
                      <i *ngSwitchCase="1"><img src="../../../../assets/icons/method-payment/dinheiro.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="2"><img src="../../../../assets/icons/method-payment/generic-card-1.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="3"><img src="../../../../assets/icons/method-payment/generic-card-2.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="4"><img src="../../../../assets/icons/method-payment/mastercard-card.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="5"><img src="../../../../assets/icons/method-payment/visa-card.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="6"><img src="../../../../assets/icons/method-payment/pix.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="7"><img src="../../../../assets/icons/method-payment/american-express.png"  style="width: 25px;"/></i>
                      <i *ngSwitchDefault><img src="../../../../assets/icons/method-payment/generic-card-1.png"  style="width: 25px;"/></i>
                  </ng-container>
                    <span>{{method.formName}}</span>
                  </div>
                  <input type="checkbox" style="height: 20px;" id="Dinheiro" name="paymentForm" (change)="toggleCollapse(method.formNumber); updateSelectedPaymentForms(method.formNumber)" [checked]="isSelectedPaymentForm(method.formNumber)">
                </div>
              </ng-container>

            </div>
            </ng-container>
          </div>

          <div class="box" style="width: 100%; padding-top: 0px;" [ngClass]="{'show': isCollapsedTroco}" *ngIf="isCollapsedTroco">
            <div class="box-tittle">
              <div>Troco</div>
            </div>
            <div class="body-box-change">
              <div class="div-buttons-change">
                <button type="button" class="buttons-change" (click)="sumChange(20)">+20</button>
                <button type="button" class="buttons-change" (click)="sumChange(50)">+50</button>
                <button type="button" class="buttons-change" (click)="sumChange(100)">+100</button>
                <button type="button" class="buttons-change" (click)="sumChange(200)">+200</button>
              </div>
              <div class="div-result-change">
                <span>Troco para: {{this.totalChange | currency:'BRL' }}</span>
                <button type="button" (click)="resetChange()">Zerar</button>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn modal-btn-negative" (click)="openModalPayment = false;">Cancelar</button>
          <button type="submit" class="btn modal-btn-positive" *ngIf="selectedPaymentForms.length != 0" (click)="hiddenModal()">Pedir a conta</button>
          <button type="button" class="btn modal-btn-positive desabled-positive" *ngIf="selectedPaymentForms.length == 0" (click)="warning()">Pedir a conta</button>
          <!-- <button type="button" class="btn btn-confirmation" (click)="openModalPayment = false; finalizationOrder()">Finalizar Comanda/Mesa</button> -->
        </div>
      </form>
    </div>
  </div>
</div>

<!--Modal adicionar Observação-->
<div class="modal" [ngClass]="{'show': openModaladdObs}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Adicionar Observação</h1>
      </div>
      <div class="modal-body">
        <div class="observation-area">
          <span>Observações:</span>
          <textarea class="text-area-observation" [value]="this.textObservationModal" (input)="capturaTexArea($event)" #inputAddObs></textarea>
        </div>
      </div>
      <div class="modal-footer justify-content-center" style="flex-direction: column;">
        <button type="button" class="btn modal-btn-negative" (click)="openModaladdObs = false; this.textObservationModal = '' ">Cancelar</button>
        <button type="button" class="btn modal-btn-positive" (click)="openModaladdObs = false; adicionarObs()">Adicionar</button>

      </div>
    </div>
  </div>
</div>

<!--Modal edição de nome-->
<div class="modal" [ngClass]="{'show': openModalNameAlteration}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="staticBackdropLabel" style="font-size: 17px; color: var(--primary-color);">Alterar nome</h1>
      </div>
      <div class="modal-body" style="padding: 1rem 0 0 0;">
        <form (ngSubmit)="alterationNameCustomer()" style="text-align: center; width: 100%;">
          <div class="box-info">
            <input class="input-name" [(ngModel)]="valueInputName" name="valueInputName" type="text" placeholder="Insira seu nome"/>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn modal-btn-negative" (click)="openModalNameAlteration = false;">Fechar</button>
            <button type="submit" class="btn modal-btn-positive" [disabled]="this.valueInputName.length < 3">Confirmar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!--Modal Editar Observação-->
<div class="modal" [ngClass]="{'show': openModalEditObs}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Editar Observação</h1>
      </div>
      <div class="modal-body">
        <div class="observation-area">
          <span>Observações:</span>
          <textarea class="text-area-observation" [value]="this.textObservationModal" (input)="capturaTexArea($event)" #inputEditObs></textarea>
        </div>
      </div>
      <div class="modal-footer justify-content-center" style="flex-direction: column;">
        <button type="button" class="btn modal-btn-negative" (click)="openModalEditObs = false; this.textObservationModal = '' ">Cancelar</button>
        <button type="button" class="btn modal-btn-positive" (click)="openModalEditObs = false; adicionarObs()">Editar</button>
      </div>
    </div>
  </div>
</div>

<!--Modal alterar mesa-->
<div class="modal" [ngClass]="{'show': openModalAlterationTable}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="staticBackdropLabel" style="font-size: 17px; color: var(--primary-color);">Alterar mesa</h1>
      </div>
      <div class="modal-body" style="padding: 1rem 0 0 0;">
        <form (ngSubmit)="alterationTableNumber(valorInputModal)" style="text-align: center; width: 100%;">
          <div style="display: flex; flex-direction: column; align-items: center;">
            <input class="input-table" [(ngModel)]="valorInputModal" name="valorInputModal" type="number" style="margin-bottom: 12px;" #inputAlterationTable/>
            <span style="margin-bottom: 8px; color: orange;" *ngIf="this.valorInputModal == '0'">Mesa invalida</span>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn modal-btn-negative" (click)="openModalAlterationTable = false;">Fechar</button>
            <button type="submit" class="btn modal-btn-positive" [disabled]="!this.valorInputModal">Confirmar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!--Modal Permissão negada-->
<div class="modal" [ngClass]="{'show': openModalPermissionRequest}" (click)="openModalPermissionRequest = false; enableScroll()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="flex-direction: column;">
        <i class="fa-solid fa-money-bill-transfer" style="font-size: 40px;color: #333;"></i>
        <span style="font-size: 20px; font-weight: bolder; color: #333;">Pedido em conta</span>
      </div>
      <div class="modal-body" style="padding: 1rem 1rem 1rem 1rem;">
        <p style="margin-bottom: 0; font-weight: 600;">Conta ja solicitada, por favor aguarde!</p>
        <!-- <p style="text-align: center; font-weight: 600; margin-bottom: 0px;">Por favor aguarde!</p> -->
      </div>
      <div class="modal-footer justify-content-center" style="flex-direction: column;">
        <button type="button" class="btn modal-btn-positive" (click)="openModalPermissionRequest = false; enableScroll()">Ok</button>
      </div>
    </div>
  </div>
</div>
