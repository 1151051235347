import { Component, OnInit, AfterViewInit, ChangeDetectorRef, HostListener, Renderer2, ElementRef, Inject, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { additional, Bag, categoryAdd, OrderUser, Pedido } from 'src/app/Interfaces/pedido';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { OrderService } from 'src/app/shared/services/Order/order.service';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { UserService } from 'src/app/shared/services/User/user.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { DOCUMENT } from '@angular/common';
import { paymentForm, Pix } from 'src/app/Interfaces/order';
import { WebhookService } from 'src/app/shared/services/WeebHook/webhook.service';
import { Subscription } from 'rxjs';
import { typeInterface } from 'src/app/Interfaces/company';
import { Coupon } from 'src/app/Interfaces/coupon';
import { Fidelity } from 'src/app/Interfaces/fidelity';
import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { Address, Cliente } from 'src/app/Interfaces/customer';
// import * as QRCode from 'qrcode';
export interface dataform{
  checked: boolean,
  value: number
}
export interface dataformAddress{
  checked: boolean,
  value: number,
  address: Address
}

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css', "../../../assets/styles/global/_modal.scss"]
})
export class OrderConfirmationComponent implements OnInit, AfterViewInit{

  //#region Variaveis Locais
    private webHookSubscription!: Subscription;
    openCardLoading = false;
    openTypePayment = false;
    openModalPix = false;
    openModal = false;
    showTopMenu = true;
    eventNavegation = false; 
    openLoadingComponent = false;
    abilitButton = false;
    deliveryFeeFree = false
    openModalNotPermission = false;
    counterFinalizado = false;
    openModalObservation = false;
    observationActive = false;
    toastCloseStoreActive = false;
    metodoSelecionado = false;
    modalPromotion = false;
    disableButton = false;
    fidelityGet = false;
    openCouponView = false;
    desvinculationCoupon = false
    openModalFidelity = false;
    productsDiscount: Pedido[] = []
    couponSelected!: Coupon
    nameCompany: string = '';
    typeParam!: typeInterface;
    AdressEmpty!: Address;
    company!: Company;
    bag: Pedido[] = [];
    name!: string;
    phoneNumber!: string;
    document: string = '';
    user!: Cliente;
    subTotal: number = 0;
    // intervalId: any;
    paymentObject: paymentForm[] = []
    flavorQuantity: number[] = []
    corretFormat!: boolean | undefined;
    countText: number = 0;
    orderObservation: string = '';
    productPromotion: any;
    productInseringPromotion: any;
    productsOrigin: any;
    productsFidelity: any[] = [];
    fidelityUser!: Fidelity;
    bags: Bag[] = [];
    methodAlteration: boolean = false;

    //Objeto do pix
    pixObject: Pix = {
      createdAt: '',
      copyPaste: '',
      qrCode: '',
      id_order: '',
      value: 0,
    }

    //Objeto do pedido
    sacola: OrderUser = {
      id_companyFK: 0,
      id_customerFK: '',
      document_customer: '',
      orderType: 0,
      orderPrice: 0,
      changeFor: '0',
      orderStatus: '',
      deliveryFee: 0,
      orderObservation: '',
      paymentForm: this.paymentObject,
      id_address: '',
      onlinePay: 0,
      products: this.bag,
      couponType: 0,
      id_coupon: '0',
      coupons: false,
      discountValue: 0
    };

    //Objeto de metodo de recebimento
    methodReceiptSelected = {
      checked: false,
      value: 0
    };

    //Objeto de endereço
    addressSelected: dataformAddress  = {
      checked: false,
      value: 0,
      address: this.AdressEmpty
    }

    //Objeto de Tipo de pagamento
    typeMethodSelected = {
      checked: false,
      value: 0
    }

    //Objeto de Formas de pagamento
    paymentFormSelected = {
      checked: false,
      value: 0,
      name: ''
    }

  //#endregion

  //#region Inicializadores

    constructor(
      private cacheService: CacheService,
      private router: Router,
      private toastr: ToastrService,
      private orderService: OrderService,
      private cartService: CartService,
      private ChangeDetectorRef: ChangeDetectorRef,
      private authLayout: AuthLayoutComponent,
      private connection: ConnectionService,
      private eventsService: EventsService,
      private userService: UserService,
      private apiService: ApiService,
      private sharedService: SharedService,
      private renderer: Renderer2,
      private elementRef: ElementRef,
      private webHookService: WebhookService,
      @Inject(DOCUMENT) private Document: Document,
      ){
        this.connection.nameCompany$.subscribe((newNameCompany) => {
          this.nameCompany = newNameCompany;
        })
        this.eventsService.eventNavegation$.subscribe((newEvent) =>{
          this.eventNavegation = newEvent
        })
        this.connection.typeParam$.subscribe((data) =>{
          this.typeParam = data;
        })
        this.connection.company$.subscribe((newCompany) =>{
          this.company = newCompany
        })
        this.connection.user$.subscribe((User) =>{
          if(User){
            this.user = User
          }
          else if(this.cacheService.getItemLocal('user_key')){
            this.connection.getUser();
          }
        })
        this.authLayout.checkScreenSize();
    }

    //Iniciado assim que o component é criado
    ngOnInit(): void {
      this.disablePullToRefresh()

      this.bag = JSON.parse(JSON.stringify(this.orderService.bag));

      let count = 0
      
      this.bag.forEach((product, index) =>{
        count = 0
        product.categories.forEach(categorie =>{
          let value = 0
          categorie.additional.forEach(additional =>{
            value += additional.quantitySub
            if(categorie.flavorCategory == 1)
            {
              count += additional.quantitySub
            }
          })
        })
        this.flavorQuantity.push(count)
      })

      this.montarPedido();

      this.webHookSubscription = this.webHookService.getUpdates().subscribe(
        (content: any) => {
          if(content.type == 5){
            const data = content.data
            if(this.pixObject.id_order){
              if(data.id_order == this.pixObject.id_order)
                if(data.status == 1){
                  clearInterval(this.pixObject.id_interval)
                  this.toastr.success('Pagamento Confirmado!', 'Successo', { positionClass: 'toast-top-right', timeOut: 4000});
                  this.router.navigate([this.nameCompany, 'order-details', this.pixObject.id_order])
                }
                else if(data.status == 6){
                  clearInterval(this.pixObject.id_interval)
                  this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado', { positionClass: 'toast-top-right', timeOut: 4000});
                  this.router.navigate([this.nameCompany, 'pedidos'])
                }
            }

          }
        },
        (error) => {
          console.error('Erro ao receber mensagem do servidor WebHook:', error);
        }
      );
    }

    //Inicializado após o termino do carregamento DOM
    ngAfterViewInit(){
      setTimeout(() => {
        this.name = this.user.name
        this.phoneNumber = this.user.phone
      });
    }

    ngOnDestroy(): void {
      clearInterval(this.pixObject.id_interval);
      // this.eventsService.eventTypeReceipt = 0
    }

  //#endregion

  //#region Funções de estilo (Style)

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    @HostListener('window:resize', [])
    onWindowResize(event: any) {
      this.checkScreenSize();
    }

    //Verifica o tamanho da tela, esconde o Menu superior se necessario
    private checkScreenSize(){
      if(window.innerWidth <= 846){

        this.showTopMenu = false;
      }
      else{
        this.showTopMenu = true;
      }
    }

    showModalNotPermission(){
      this.openModalNotPermission = true;
    }

    //Função responsavel para não mostrar o modal caso tenha ido editar o usuario
    alterationEvent(){
      this.eventsService.eventNavegationSubject.next(true)
      this.userService.editingUserSubject.next(true);
      this.router.navigate([this.nameCompany, 'user-data'])
    }

    //O primeiro modal a ser aberto, quando o component inicia
    showModal(){
      setTimeout(() => {
        this.openModal = true
      }, 100);
      this.ChangeDetectorRef.detectChanges();
    }

    checkInput(): boolean{
      let sucess = false;
      let documentnotFormatted = this.document.replace(/\D/g, '');
      if(documentnotFormatted.length == 12 || documentnotFormatted.length == 14 || documentnotFormatted.length == 0){
        sucess = true
      }
      else{
        sucess = false;
      }

      return sucess
    }

    //Função que é chamada para fazer a verificação se todos campos estão requeridos para abilitar o botão de finalizar o pedido
    AbilitButton(){
      if(this.methodReceiptSelected.value == 3){
        if(this.sacola.paymentForm.length != 0){
          // this.abilitButton = this.checkInput();

          if(this.corretFormat == undefined)
          {
            this.abilitButton = true
          }
          else if(this.corretFormat == true){
            this.abilitButton = true;
          }
          else{
            this.abilitButton = false;
          }

        }
        else{
          this.abilitButton = false;
        }
      }
      else if(this.methodReceiptSelected.value == 5)
      {
        if(this.addressSelected.checked){
          if(this.sacola.paymentForm.length != 0){
            // this.abilitButton = this.checkInput();
            if(this.corretFormat == undefined)
            {
              this.abilitButton = true
            }
            else if(this.corretFormat == true){
              this.abilitButton = true;
            }
            else{
              this.abilitButton = false;
            }
          }
          else{
            this.abilitButton = false;
          }
        }
        else{
          this.abilitButton = false;
        }
      }
      else{
        this.abilitButton = false;
      }
      // console.log(this.sacola)
    }

    verificationOpentypePayment(data: { checked: boolean, value: number }){
      if(data.value == 5){
        this.openTypePayment = false;
        
      }
      else if(data.value == 3){
        this.openTypePayment = true;
      }
      else{
        this.openTypePayment = false;
      }
      // this.ChangeDetectorRef.detectChanges();
    }

    showModalObservation(){
      this.openModalObservation = true;
    }

    confirmObservation(){
      this.openModalObservation = false
      this.sacola.orderObservation = this.orderObservation
      if(this.sacola.orderObservation?.length != 0){
        this.observationActive = true;
      }
      else{
        this.observationActive = false;
      }

      // console.log(this.sacola.orderObservation)
    }

    cancelObservation(){
      this.openModalObservation = false;
      this.orderObservation = this.sacola.orderObservation

      if(!this.observationActive){
        this.sacola.orderObservation = '';
      }

      // console.log(this.sacola.orderObservation)
    }

    valueTextArea(event: any){
      const inputElement = event?.target as HTMLInputElement;

      if(inputElement.value.length <= 200){
        this.orderObservation = inputElement.value
        this.countText = this.orderObservation.length;
      }
      else[
        inputElement.value = this.orderObservation
      ]

    }

    viewCoupon(event: any){
      if(event == true){
        this.openCouponView = true;
      }
      else{
        this.openCouponView = false;
        setTimeout(() => {
          window.scrollTo({
            behavior: 'instant' as ScrollBehavior,
            top: document.documentElement.scrollHeight,
            left: 0,
          });
        }, 10);

      }
    }

  //#endregion

  //#region Funções de Lógica (Logic)

    //#region box selecteds

      goback(): void{
        if(this.company.company.status == 1){
          this.router.navigate([this.nameCompany, 'carrinho']);
          this.eventsService.eventNavegationSubject.next(false)
          this.eventsService.eventTypeReceiptSubject.next(0)
        }
        else{
          this.router.navigate([this.nameCompany]);
          this.eventsService.eventNavegationSubject.next(false)
          this.eventsService.eventTypeReceiptSubject.next(0)
        }

      }

      //Responsavel pelo metodo de recebimento do pedido
      methodSelected(data: { checked: boolean, value: number })
      {

        if(data.checked == false && data.value == 0)
        {
          const paymentEmpty = {checked: false, value: 0, name: ''}
          const dataAddress: dataformAddress = {checked: false, value: 0, address: this.AdressEmpty}
          this.addressSelected = dataAddress;
          this.typeMethodSelected = data;
          this.paymentFormSelected = paymentEmpty

          // limpa o Tipo de pagamento
          this.sacola.onlinePay = data.value

          // limpa a Forma de pagamento
          this.sacola.paymentForm = []
          this.paymentObject = []

          // limpa o endereço
          this.sacola.id_address = '';
          this.sacola.deliveryFee = 0;
          

          //Altera os produtos para o padrão inicial qualse tenha sido alterado
          if(this.sacola.coupons){
            if(this.sacola.couponType == 3){
              this.bag = JSON.parse(JSON.stringify(this.orderService.bag));
              this.sacola.products = this.bag
              this.sacola.orderPrice = this.calcTotalOrder();
              this.methodAlteration = true;
            }
            else{
              this.sacola.orderPrice = this.calcTotalOrder();
            }
          }
          else{
            this.sacola.orderPrice = this.calcTotalOrder();
          }

          
          if(this.sacola.couponType == 3 && this.sacola.coupons){
            this.sharedService.methodReceipt.next(true);
          }
        }
        
        //recebe o metodo de recebimento
        this.sacola.orderType = data.value

        this.methodReceiptSelected = data

        this.verificationOpentypePayment(data)

        this.AbilitButton();
        this.sacola = { ...this.sacola };
      }

      //Lógica para o endereço
      address(data: { checked: boolean, value: number, address: Address }){
        if(data.checked == false && data.value == 0 && data.address == undefined)
        {
          const dataEmpty = {checked: false, value: 0}
          const paymentEmpty = {checked: false, value: 0, name: ''}

          this.openTypePayment = false;
          this.typeMethodSelected = dataEmpty
          this.paymentFormSelected = paymentEmpty
          this.addressSelected = data

          // limpa o Tipo de pagamento
          this.sacola.onlinePay = data.value

          // limpa a Forma de pagamento
          this.sacola.paymentForm = []
          this.paymentObject = []


          //limpa o endereço
          this.sacola.id_address = '';
          this.sacola.deliveryFee = 0;


          if(this.sacola.coupons){
            if(this.sacola.couponType == 3){
              this.bag = JSON.parse(JSON.stringify(this.orderService.bag));
              this.sacola.products = this.bag
              this.sacola.orderPrice = this.calcTotalOrder();
              this.methodAlteration = true;
            }
            else{
              this.sacola.orderPrice = this.calcTotalOrder();
            }
          }
          else{
            this.sacola.orderPrice = this.calcTotalOrder();
          }

          if(this.sacola.couponType == 3 && this.sacola.coupons){
            this.sharedService.methodReceipt.next(true);
          }

          this.sacola = { ...this.sacola };
        }
        else{
          this.sacola.id_address = data.address.id_address;
          this.addressSelected = data

          if(typeof data.address.deliveryPrice == 'number')
          {
            if(this.sacola.deliveryFee == 0){

              this.sacola.deliveryFee = data.address.deliveryPrice;
              this.sacola.orderPrice += data.address.deliveryPrice;    
            }
          }
          if(data.address.deliveryPrice == 0)
          {
            this.deliveryFeeFree = true;
          }
    
          this.openTypePayment = true;

          this.sacola = { ...this.sacola };
        }
      }

      //Tipo de pagamento do pedido
      typePayment(data: { checked: boolean, value: number })
      {

        this.typeMethodSelected = data
        if(data.checked == false && data.value == 0)
        {
          const paymentEmpty = {checked: false, value: 0, name: ''}
          this.metodoSelecionado = false
          this.paymentFormSelected = paymentEmpty
          // limpa a Forma de pagamento
          this.sacola.paymentForm = []
          this.paymentObject = []
          this.sacola.onlinePay = 0;

          //Altera os produtos para o padrão inicial qualse tenha sido alterado
          this.bag = JSON.parse(JSON.stringify(this.orderService.bag));
          this.sacola.products = this.bag
        }

        this.metodoSelecionado = true
        this.AbilitButton();
      }

      //Forma de pagamento do pedido
      formPayment(data: { checked: boolean, value: number, name: string, id_form: string})
      {
        this.paymentFormSelected = data
        if(data.checked == false && data.value == 0)
        {
          this.modalPromotion = false;
          this.sacola.paymentForm = []
          this.paymentObject = []
          this.bag = JSON.parse(JSON.stringify(this.orderService.bag));
          this.sacola.products = this.bag
          this.sacola.onlinePay = 0;
        }
        else{

          const form: paymentForm = {
            id_formpayment: data.id_form,
            changeFor: "0",
            onlinePay: 0,
            payStatus: 0,
            paymentName: this.paymentFormSelected.name,
            paymentForm: this.paymentFormSelected.value,
            paymentValue: this.sacola.orderPrice.toString()
          }

          if(data.value == 0){
            form.onlinePay = 1
            this.sacola.onlinePay = 1;
          }
          else{
            form.onlinePay = 0
            this.sacola.onlinePay = 0;
          }

          //adiciona ele no array e adiciona o array no pedido
          this.paymentObject.push(form)
          this.sacola.paymentForm = this.paymentObject

          if(this.company.configs.edgePix == 1){
            if(data.value == 2 || data.value == 0){
              this.promotionPayment(this.sacola)
            }
          }

        }
        this.AbilitButton();
      }

      //Troco
      changeFor(event: any){
        if(typeof event == 'number')
        {
            this.sacola.changeFor = event.toString()
            this.sacola.paymentForm[0].changeFor = event.toString()
        }
      }

    //#endregion

    //#region Coupons

      //Quando o cupom selecionado é ativado
      coupomSelected(event: any){

        if(event.check){
          let coupon: Coupon = event.coupon
          if(coupon.couponType == 1 || coupon.couponType == 3){
            if(coupon.percent != 0)
              {
                const discontFormated = coupon.percent / 100;
                let discont;
                if(this.sacola.orderType == 5){
                  const orderPrice = this.sacola.orderPrice - this.sacola.deliveryFee;
                  discont = orderPrice * discontFormated;
                  this.sacola.orderPrice = orderPrice - discont + this.sacola.deliveryFee;
                }
                else{
                  discont = this.sacola.orderPrice * discontFormated;
                  this.sacola.orderPrice = this.sacola.orderPrice - discont;
                }
                this.sacola.discountValue = discont;
                this.sacola.id_coupon = coupon.id_coupon;
                this.sacola.coupons = true;
                this.sacola.couponType = 1;
            }
            else if(coupon.value != 0){
              let discont = coupon.value
              this.sacola.orderPrice = this.sacola.orderPrice - discont
              this.sacola.discountValue = discont;
              this.sacola.id_coupon = coupon.id_coupon
              this.sacola.coupons = true,
              this.sacola.couponType = 2
            }
            else if(coupon.deliveryFree == true){
              const desconto = this.sacola.deliveryFee
              this.sacola.discountValue = desconto
              this.sacola.id_coupon = coupon.id_coupon,
              this.sacola.coupons = true
              this.deliveryFeeFree = true
              this.sacola.couponType = 3
              this.sacola.orderPrice = this.sacola.orderPrice - this.sacola.deliveryFee
              this.sacola.deliveryFee = 0
            }
          }
          else if(coupon.couponType == 2){
            this.productsDiscount = this.sacola.products.filter(prodOrder => coupon.discountProducts.includes(prodOrder.id_product.toString())).map(prodOrder => ({ ...prodOrder }));
          
            if (this.productsDiscount.length === 1) {
              this.couponSelected = coupon
              this.applyCouponDiscount({ coupon, product: this.productsDiscount[0] });
            } else if (this.productsDiscount.length > 1) {
              if(coupon.percent != 0){
                this.openModalFidelity = true;
                this.couponSelected = coupon;
              }
              else{
                this.couponSelected = coupon
                this.applyCouponDiscount({ coupon, product: this.productsDiscount[0] });
              }

            } 
          }
        }
        else{
          this.deslectedCupom();
        }
      }

      //Aplica o desconto de cupons do tipo produto 
      applyCouponDiscount(event: any) {
        const product = event.product;
        const coupon = event.coupon;
        const matchingProducts = this.sacola.products.filter(productOrder => productOrder.id_product === product.id_product);

        if(matchingProducts.length > 1){

          const matchingItem = matchingProducts.find(existingProduct => {
            if (product.observations !== '' && existingProduct.observations !== '') {
              return (
                product.observations === existingProduct.observations &&
                this.additionalsEqual(product.categories, existingProduct.categories)
              );
            } else if (product.observations === '' && existingProduct.observations === '') {
              return this.additionalsEqual(product.categories, existingProduct.categories);
            }
            return false;
          });

          if(matchingItem){

            const discount = this.calculateDiscount(matchingItem, coupon);
            this.applyDiscountToProduct(matchingItem, discount);
    
            this.sacola.discountValue = discount;
            this.sacola.id_coupon = coupon.id_coupon;
            this.sacola.coupons = true;
            this.sacola.couponType = 2;
            this.sacola.orderPrice = this.sacola.orderPrice - discount
            this.toastr.success(`Cupom aplicado`, 'Sucesso', { positionClass: 'toast-top-right', timeOut: 3000});
            console.log(this.couponSelected)
          }
        }
        else{
          const matchingItem = this.sacola.products.find(productOrder => productOrder.id_product == product.id_product)

          if(matchingItem){
            const discount = this.calculateDiscount(matchingItem, coupon);
            this.applyDiscountToProduct(matchingItem, discount);
    
            this.sacola.discountValue = discount;
            this.sacola.id_coupon = coupon.id_coupon;
            this.sacola.coupons = true;
            this.sacola.couponType = 2;
            this.sacola.orderPrice = this.sacola.orderPrice - discount
            this.toastr.success(`Cupom aplicado`, 'Sucesso', { positionClass: 'toast-top-right', timeOut: 3000});
          }

        }
        this.openModalFidelity = false;
      }

      //Aplica o disconto no produto
      private applyDiscountToProduct(matchingItem: any, discount: number) {
        if (matchingItem.quantity > 1) {
          const productDiscount = { ...matchingItem };
          productDiscount.totalPrice = matchingItem.totalPrice / matchingItem.quantity;
          productDiscount.oldPrice = productDiscount.totalPrice;
          productDiscount.discountValue = discount;
          productDiscount.totalPrice -= discount;
          productDiscount.discount = true;
          productDiscount.quantity = 1;
      
          const priceDiscount = matchingItem.totalPrice / matchingItem.quantity;
          matchingItem.totalPrice = priceDiscount * (matchingItem.quantity - 1);
          matchingItem.quantity -= 1;
      
          this.sacola.products.push(productDiscount);
        } else {
          matchingItem.oldPrice = matchingItem.totalPrice;
          matchingItem.totalPrice -= discount;
          matchingItem.discount = true;
          matchingItem.discountValue = discount;
        }
      }

      //Calcula quanto de desconto o produto recebera
      private calculateDiscount(matchingItem: any, coupon: any): number {
        if (coupon.percent != 0) {
          if(matchingItem.quantity > 1){
            const totalPrice = matchingItem.totalPrice / matchingItem.quantity
            return totalPrice * (coupon.percent / 100);
          }
          else{
            return matchingItem.totalPrice * (coupon.percent / 100);
          }

        } else if (coupon.value != 0) {
          return coupon.value;
        }
        return 0;
      }
      
      //faz a comparação dos adicionais nos produtos
      private additionalsEqual(additionals1: categoryAdd[], additionals2: categoryAdd[]): boolean {
        // Se o comprimento dos arrays for diferente, eles não são iguais
        if (additionals1.length !== additionals2.length) {
          return false;
        }
    
        // Verificar se todos os ids de additionals1 existem em additionals2 e vice-versa
        for (const category1 of additionals1) {
          const matchingCategory = additionals2.find(category2 => category2.id_category === category1.id_category);
    
          if (!matchingCategory) {
            return false;
          }
    
          if (!this.additionalArraysEqual(category1.additional, matchingCategory.additional)) {
            return false;
          }
        }
    
        return true;
      }

      //Faz a comparação as categorias de adicionais nos produtos
      private additionalArraysEqual(additionals1: additional[], additionals2: additional[]): boolean {
        for (const additional1 of additionals1) {
          if (!additionals2.some(additional2 => additional2.add_id_product === additional1.add_id_product)) {
            return false;
          }
        }
    
        for (const additional2 of additionals2) {
          if (!additionals1.some(additional1 => additional1.add_id_product === additional2.add_id_product)) {
            return false;
          }
        }
    
        return true;
      }

      //Quando o usuario cancela a seleção de produtos que ganharão o desconto
      cancelSelectCoupon(){
        this.desvinculationCoupon = true;
        this.openModalFidelity = false
        setTimeout(() => {
          this.desvinculationCoupon = false;
        }, 100);
      }

      //Desseleciona o cupom e retira suas propriedades no pedido
      deslectedCupom(){
        this.bag = JSON.parse(JSON.stringify(this.orderService.bag));
        this.sacola.products = this.bag

        this.sacola.products.forEach(product => {
          product.discount = false,
          product.discountValue = 0,
          product.oldPrice = 0
        });

        let coupon!: Coupon;
        this.couponSelected = coupon

        this.productsDiscount = []

        if(this.sacola.discountValue)
          {
            if(this.sacola.couponType == 3 && !this.methodAlteration){
              this.sacola.deliveryFee = this.sacola.discountValue;
            }

            if(!this.methodAlteration){
              this.sacola.orderPrice = this.sacola.orderPrice + this.sacola.discountValue;
            }

            this.deliveryFeeFree = false;
            
            this.sacola.id_coupon = '0';
            this.sacola.coupons = false;
            this.sacola.discountValue = 0;
            this.sacola.couponType = 0;
            this.methodAlteration = false;
          }
      }

    //#endregion

    //#region Montagem e finalização do pedido

      //Resgata a fidelidade do usuario caso não tenha sido carregada
      // getFidelity(){
      //   if(this.company){
      //     if(this.user){
      //       if(!this.fidelityGet)
      //       {
      //         this.apiService.getFidelity(this.user.id_customer, this.company.company.id_company).subscribe(
      //           (data) =>{
      //             console.log(data)
      //             this.fidelityUser = data;
      //             this.fidelityGet = true;

      //           },
      //           (error) =>{
      //             console.log(error);
      //           }
      //         )
      //       }
      //     }
      //   }

      // }

      //Monta o objeto de pedido de usuario, tanto para Pedido em balcão quanto em delivery
      montarPedido(){
        this.sacola = {
          id_customerFK: this.user.id_customer,
          document_customer: this.document,
          id_companyFK: this.company.company.id_company,
          orderObservation: '',
          products: this.bag,
          changeFor: '0',
          orderType: 0,
          orderStatus: '1',
          deliveryFee: 0,
          paymentForm: this.paymentObject,
          onlinePay: 0,
          orderPrice: this.calcTotalOrder(),
          id_coupon: '0',
          discountValue: 0,
          coupons: false,
          couponType: 0
        }
      }

      //Manda o pedido para o serviço e limpa os itens do carrinho
      finalizationOrder(){
        this.disableButton = true;

        this.apiService.verifiStatusCompany(this.company.company.id_company).subscribe(
          (data) =>{
            if(data[0].open){
              this.eventsService.eventNavegationSubject.next(false)
              this.eventsService.eventTypeReceiptSubject.next(0)
        
              if(this.sacola.onlinePay == 1){
                this.paymentPix(this.sacola);
              }
              else{
                this.orderService.OrderUser(this.sacola);
                this.router.navigate([this.nameCompany, 'processing']);
              }
        
              this.cartService.clearCart();
            }
            else{
              if(!this.toastCloseStoreActive)
              {
                this.toastCloseStoreActive = true;
                this.toastr.error('Desculpe, mas a loja já fechou!', 'Loja Fechada',
                { positionClass: 'toast-top-right', timeOut: 0, tapToDismiss: true,}
                ).onHidden.subscribe(() =>{ this.toastCloseStoreActive = false});
              }

              this.connection.Empresa(this.nameCompany, this.typeParam.type).subscribe(
                (data) =>{
                  console.log('Status da loja atualizado');
                },
                (error) =>{
                  console.log('Erro inesperado', error)
                }
              )

            }
          },
          (error) =>{
            console.log(error);
          }
        )
      }  

      //Calcula o total da ordem para ser montado o objeto e feito o request
      calcTotalOrder(){
        let total = 0
        let subTotal = 0
        this.bag.forEach(product => {
          if(product.discount){
            subTotal += product.totalPrice + product.discountValue
          }
          else{
            subTotal += product.totalPrice
          }
          total += product.totalPrice
        });

        this.subTotal = subTotal

        return total
      }

    //#endregion

    //#region Promoção para pagamento Pix (Borda Pix)

      //Compara se os objetos são iguais, todos as propriedades e valores
      equalsObjects(obj1: any, obj2: any): boolean {
        if (typeof obj1 !== typeof obj2) {
            return false;
        }
    
        if (typeof obj1 === 'object') {
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);
    
            if (keys1.length !== keys2.length) {
                return false;
            }
    
            for (const key of keys1) {
                if (!this.equalsObjects(obj1[key], obj2[key])) {
                    return false;
                }
            }
            return true;
        }
        return obj1 === obj2;
      }

      //Detecta, se existe promoção para aqueles produtos
      promotionPayment(products: OrderUser){
        console.log(products)
        this.apiService.promotionPaymentForm(products).subscribe(
          (data) =>{
            console.log(data);
            if(!data.empty){
              this.productInseringPromotion = data.principalProducts
              this.productPromotion = data.promoProducts[0]
              console.log(this.productPromotion)
              this.modalPromotion = true;
            }
          },
          (error) =>{
            console.log(error);
          }
        )
      }

      //Faz a lógica de localizar e adicionar a promoção no produto
      promotionSelected(data:{productOrder: Pedido, productPromotion: any}){   
          for (const product of this.sacola.products) {
            if(this.equalsObjects(product, data.productOrder)){
              if(product.quantity > 1){

                const newProduct = JSON.parse(JSON.stringify(product));
                newProduct.quantity = 1;
                newProduct.totalPrice = newProduct.baseValue;       
                if (newProduct.flavorsValue) {
                  newProduct.flavorsValue = newProduct.baseFlavorsValue;
                }

                product.quantity = product.quantity - 1;
                product.totalPrice -= newProduct.baseValue;
                if (product.flavorsValue) {
                  product.flavorsValue -= newProduct.baseFlavorsValue;
                }

                this.sacola.products.push(newProduct);
                data.productPromotion.promotionPay = true;
                newProduct.categories.push(data.productPromotion);

                let count = 0
                this.sacola.products.forEach((product, index) =>{
                  count = 0
                  product.categories.forEach(categorie =>{
                    let value = 0
                    categorie.additional.forEach(additional =>{
                      value += additional.quantitySub
                      if(categorie.flavorCategory == 1)
                      {
                        count += additional.quantitySub
                      }
                    })
                  })
                  this.flavorQuantity.push(count)
                })


                break;
              }
              else{
                data.productPromotion.promotionPay = true;
                product.categories.push(data.productPromotion)
                break;
              }

            }
          }
          console.log(this.sacola.products);
          this.bag = JSON.parse(JSON.stringify(this.sacola.products));
          this.modalPromotion = false;

      }

      //Remove a borda Pix gratis
      removeEdge(){
        this.bag = JSON.parse(JSON.stringify(this.orderService.bag));
        this.sacola.products = this.bag
      }

    //#endregion

    //#region Pix

      //Quando o usuario seleciona o pix, função responsavel de montalo
      paymentPix(sacola: OrderUser){
        this.openCardLoading = true;
        this.orderService.OrderUserPaymentPix(sacola).subscribe(
          (data) =>{
            this.apiService.paymentPix(data).subscribe((dataPayment) =>{
              this.openModalPix = true;
              this.openCardLoading = false;

              this.pixObject = {
                id_order: data.id_order,
                createdAt: data.createdAt,
                copyPaste: dataPayment.pagamento,
                qrCode: 'data:image/png;base64,' + dataPayment.pagamentoQrCode,
                value: dataPayment.valorTotal,
              }
              this.startVerification(data.id_order, this.company.company.id_company);
            },
            (error) =>{
              console.log(error)
              this.toastr.error('Erro ao solicitar pagamento', 'Erro', { positionClass: 'toast-top-right', timeOut: 4000});
              this.openCardLoading = false;
            })
          },(error) =>{
            console.log(error)
            this.openCardLoading = false;

          });
      }

      //Envia uma requisição para o servidor webhook para fazer a verificação
      startVerification(id_order: string, id_company: number){
        this.apiService.startVerification(id_order, id_company).subscribe(
          () =>{
            console.log('Server started')
            this.pixObject.id_interval = setInterval(() => {
              this.getStatusOrder(id_order)
            }, 10000) 
            console.log(this.pixObject)
          },
          (error) =>{
            console.log(error);
          }
        )
      }

      //Resgata a confirmação de pagamento pix
      getStatusOrder(id_order: string){
        this.apiService.getStatusOrder(id_order).subscribe(
          (data) =>{
            if(data.status == 1 || data.status == 2){
              clearInterval(this.pixObject.id_interval)
              this.toastr.success('Pagamento Confirmado!', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 4000});
              this.router.navigate([this.nameCompany, 'order-details', this.pixObject.id_order]);
            }
            else if(data.status == 10){
              clearInterval(this.pixObject.id_interval)
              this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado', { positionClass: 'toast-top-right', timeOut: 4000});
              this.router.navigate([this.nameCompany, 'pedidos']);
            }
          },
          (error) =>{

          }
        )
      }

      //Fecha o contador quando o tempo termina
      finalizationCounter(event: any){
        this.counterFinalizado = event
      }

      //Volta para view de pedidos
      backOrders(){
        this.router.navigate([this.nameCompany, 'pedidos']);
      }

    //#endregion

    //#region Formatação de documentos CPF - CNPJ

      formatarDocumento(event: any) {
        const newValue = event.target.value;

        const rawValue = newValue.replace(/\D/g, '');
        let formatted = this.formatarCPF(rawValue);

        if(rawValue.length == 0){
          this.AbilitButton()
          this.corretFormat = undefined
        }
        if(rawValue.length >= 1  && rawValue.length < 11)
        {
          this.abilitButton = false;
          this.corretFormat = false
        }
        else if(rawValue.length == 11){
          this.corretFormat = true
          this.AbilitButton()

        }
        else if(rawValue.length >= 12 && rawValue.length < 14){
          this.abilitButton = false;
          this.corretFormat = false;
        }
        else if(rawValue.length == 14){
          this.corretFormat = true
          this.AbilitButton()

        }

        if (rawValue.length <= 11) {
          formatted = this.formatarCPF(rawValue);
        } else {
          formatted = this.formatarCNPJ(rawValue);
        }


        this.document = formatted
        this.sacola.document_customer = this.document
      }

      formatarCPF(cpf: string): string {

        const areaCode = cpf.substring(0, 3);
        const firstPart = cpf.substring(3, 6);
        const secondPart = cpf.substring(6, 9);
        const treePart = cpf.substring(9, 11);
        if(cpf.length <= 2)
        {
          return `${areaCode}`;
        }
        else if(cpf.length >= 2 && cpf.length <= 5 )
        {
          return `${areaCode}.${firstPart}`
        }
        else if(cpf.length >= 5 && cpf.length <= 8 )
        {
          return `${areaCode}.${firstPart}.${secondPart}`
        }
        else
        {
          return `${areaCode}.${firstPart}.${secondPart}-${treePart}`
        }
      }

      formatarCNPJ(cnpj: string): string {

        const area1 = cnpj.substring(0, 2)
        const area2 = cnpj.substring(2, 5)
        const area3 = cnpj.substring(5, 8)
        const area4 = cnpj.substring(8, 12)
        const area5 = cnpj.substring(12, 14)



        if(cnpj.length <= 13)
        {
          return `${area1}.${area2}.${area3}/${area4}-`
        }
        else{
          return `${area1}.${area2}.${area3}/${area4}-${area5}`
        }
      }

    //#endregion

  //#endregion

}
