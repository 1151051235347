import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Coupon } from 'src/app/Interfaces/coupon';
import { Pedido } from 'src/app/Interfaces/pedido';

@Component({
  selector: 'app-modal-fidelity',
  templateUrl: './modal-fidelity.component.html',
  styleUrls: ['./modal-fidelity.component.css', "../../../../assets/styles/global/_modal.scss"]
})
export class ModalFidelityComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  productOrderSelected!: Pedido;
  checked: boolean[] = []
  @Input() productsOrder!: Pedido[];
  @Input() modalShow: boolean = false;
  @Input() coupon!: Coupon;
  @Output() selected: EventEmitter<{ product: Pedido, coupon: Coupon}> = new EventEmitter<{ product: Pedido, coupon: Coupon }>();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.checked = Array(this.productsOrder.length).fill(false);
  }

  ngAfterViewInit(): void {
      
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['coupon']) {
      if(this.coupon != undefined)
      {
        this.productsOrder.forEach(product => {
          let discount = 0;
          const discontFormated = this.coupon.percent / 100;
          discount = product.totalPrice * discontFormated

          product.oldPrice = product.totalPrice / product.quantity
          product.discountValue = discount
          product.totalPrice = product.oldPrice - discount
          product.discount = true;
        });
      }
    }
  }

  ngOnDestroy(): void {
    let prod!: Pedido;
    this.productOrderSelected = prod;
  }

  aceept(){
    const data = {product: this.productOrderSelected, coupon: this.coupon}
    this.selected.emit(data)
    this.checked = Array(this.productsOrder.length).fill(false);
  }

  cancelOperation(){
    this.cancel.emit(true)
    this.modalShow = false;
    this.checked = Array(this.productsOrder.length).fill(false);
  }

  selectedProd(event: any, index: number){
    const data = event;
    data.checked = !data.checked
    this.checked[index] = data.checked
    if(data.checked){
      this.checked.forEach((check, i) => {
        if(index != i){
          this.checked[i] = false;
        }

      });
      this.productOrderSelected = data.value
    }
    else{
      let prod!: Pedido;
      this.productOrderSelected = prod
    }

  }
}
