<div class="menu-bottom" *ngIf="this.company && !this.readOnlyState" #bottomMenu>
  <div class="menu-bottom-buttons">

    <!--#region Inicio-->
    <div class="div-first" [ngClass]="{'tree': this.userConfirmation}" >
      <!--Button para o Modo balcão-->
        <ng-container *ngIf="alternationButton == 2">
          <div class="div-buttons" #button [routerLink]="['/', this.nameCompany, 'P']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" type="button" [ngClass]="{'active': linkRoute}">
            <span class="bar"></span>
            <span aria-hidden="true" class="fa fa-home button-bottom"></span>
            <span class="title-icon">Inicio</span>
          </div>
        </ng-container>

        <!--Button para o Modo mesa-->
        <ng-container *ngIf="alternationButton == 1">
          <div class="div-buttons" #button [routerLink]="['/', nameCompany, 'B']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" type="button" [ngClass]="{'active': linkRoute}">
            <span class="bar"></span>
            <span aria-hidden="true" class="fa fa-home button-bottom"></span>
            <span class="title-icon">Inicio</span>
          </div>
        </ng-container>
        <!--Button para o Modo Delivery-->
        <ng-container *ngIf="alternationButton == 0">
          <div class="div-buttons" #button [routerLink]="['/', nameCompany]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" type="button" [ngClass]="{ 'active': isActive() }">
            <span class="bar"></span>
            <span aria-hidden="true" class="fa fa-home button-bottom"></span>
            <span class="title-icon">Inicio</span>
          </div>
        </ng-container>
    </div>
    <!--#endregion-->

    <!--#region Carrinho-->

    <!--Botão de carrinho padrão-->
    <ng-container *ngIf="this.statusOpen && this.permissionRequest">
      <div class="div-first" [ngClass]="{'tree': this.userConfirmation}">
        <div class="div-buttons" #button  [routerLink]="['/', nameCompany, 'carrinho']" (click)="this.eventService.eventFilterSubject.next(false);" routerLinkActive="active" type="button">
          <span class="bar"></span>
          <i class="fa-solid fa-cart-shopping button-bottom"></i>
          <span class="title-icon">Carrinho</span>
          <span *ngIf="this.counterCart > 0" class="icon-quantity" [ngClass]="{'vibration' : this.vibrationCircle}">{{ counterCart }}</span>
        </div>
      </div>
    </ng-container>

    <!--Botão para quando a loja estiver fechada-->
    <ng-container *ngIf="!this.statusOpen && this.permissionRequest ">
      <div class="div-first" [ngClass]="{'tree': this.userConfirmation}" (click)="this.openModalCloseStore = true; disableScroll()">
        <div class="div-buttons" #button type="button">
          <span class="bar"></span>
          <i class="fa-solid fa-cart-shopping button-bottom"></i>
          <span class="title-icon">Carrinho</span>
        </div>
      </div>
    </ng-container>


    <!--Botão para quando o pedido estiver em conta-->
    <ng-container *ngIf="!this.permissionRequest">
      <div class="div-first" [ngClass]="{'tree': this.userConfirmation}"  (click)="disableScroll(); showModalPermission()">
        <div class="div-buttons" #button type="button">
          <span class="bar"></span>
          <i class="fa-solid fa-cart-shopping button-bottom"></i>
          <span class="title-icon">Carrinho</span>
        </div>
      </div>
    </ng-container>

    <!--#endregion-->

    <div class="div-first" *ngIf="!this.showButtons && this.activeButtonWaiter && this.company.configs.accountActive == 1" [ngClass]="{'tree': this.showButtons, 'active': this.openModalCallCard}">

      <!--Chamar garçom para o modo comanda-->
      <div class="div-buttons" #button type="button" *ngIf="!this.numberTable" (click)="this.openModalCard()">
        <span class="bar"></span>
        <i class="fa-regular fa-bell button-bottom callWaiter"></i>
        <span class="title-icon">Garçom</span>
      </div>

      <!--Chamar garçom para o modo mesa-->
      <div class="div-buttons" #button type="button" *ngIf="this.numberTable" (click)="this.openModalCallTable = true">
        <span class="bar"></span>
        <i class="fa-regular fa-bell button-bottom callWaiter"></i>
        <span class="title-icon">Garçom</span>
      </div>
    </div>

    <!--Consumo-->
    <div class="div-first" *ngIf="!this.showButtons && !this.activeButtonWaiter" [ngClass]="{'tree': this.showButtons}">
      <div class="div-buttons" #button [routerLink]="['/', this.nameCompany, 'consumption']" (click)="this.eventService.eventFilterSubject.next(false);" routerLinkActive="active" type="button">
        <span class="bar"></span>
        <i class="fa-solid fa-book button-bottom consumo"></i>
        <span class="title-icon">Consumo</span>
      </div>
    </div>

    <!--Pedidos-->
    <div class="div-first" *ngIf="this.userConfirmation && this.showButtons" [ngClass]="{'tree': this.showButtons}">
      <div class="div-buttons" #button [routerLink]="['/', this.nameCompany, 'pedidos']" (click)="this.eventService.eventFilterSubject.next(false);" routerLinkActive="active" type="button" [ngClass]="{'active': activeLink}">
        <span class="bar"></span>
        <i class="fa-solid fa-book button-bottom pedidos"></i>
        <span class="title-icon">Pedidos</span>
      </div>
    </div>

    
    <div class="div-first" *ngIf="this.showButtons" [ngClass]="{'tree': this.showButtons}">
      <div class="div-buttons" #button [routerLink]="['/', this.nameCompany, 'user']" (click)="this.eventService.eventFilterSubject.next(false);" routerLinkActive="active" type="button" [ngClass]="{'active': activeLink}">
        <span class="bar"></span>
        <i class="fa-solid fa-user button-bottom"></i>
        <span class="title-icon">Perfil</span>
        <ng-container *ngIf="this.fidelityUser">
          <span *ngIf="this.fidelityUser.fidelityApproved" class="icon-quantity">1</span>
        </ng-container>

      </div>
    </div>


  </div>
</div>


<!--Modal de loja fechada-->
<div class="modal" [ngClass]="{'show': openModalCloseStore}" (click)="openModalCloseStore = false; enableScroll()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="flex-direction: column;">
        <i class="fa-solid fa-shop-slash" style="font-size: 40px;color: #333;"></i>
        <span style="font-size: 20px; font-weight: bolder; color: #333;">Loja Fechada</span>
      </div>
      <div class="modal-body" style="padding: 1rem 1rem 1rem 1rem;">
        <p style="margin-bottom: 0; font-weight: 600;">No momento estamos fechados!</p>
        <p style="text-align: center; font-weight: 600; margin-bottom: 0px;"><span style="color: var(--primary-color);" (click)="redirectModal()">Clique aqui</span> para conhecer nossos horários de atendimento!</p>
      </div>
      <div class="modal-footer justify-content-center" style="flex-direction: column;">
        <button type="button" class="btn modal-btn-positive" (click)="openModalCloseStore = false; enableScroll()">Ok</button>
      </div>
    </div>
  </div>
</div>

<!--Modal de pedido em conta-->
<div class="modal" [ngClass]="{'show': openModalNotPermission}" (click)="openModalNotPermission = false; enableScroll()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="flex-direction: column;">
        <i class="fa-solid fa-money-bill-transfer" style="font-size: 40px;color: #333;"></i>
        <span style="font-size: 20px; font-weight: bolder; color: #333;"></span>
      </div>
      <div class="modal-body" style="padding: 1rem 1rem 1rem 1rem;">
        <p style="margin-bottom: 0; font-weight: 600;">Pedido em conta!</p>
        <p style="text-align: center; font-weight: 600; margin-bottom: 0px;">Não é possivel fazer pedido, por favor aguarde a conta!</p>
      </div>
      <div class="modal-footer justify-content-center" style="flex-direction: column;">
        <button type="button" class="btn modal-btn-positive" (click)="openModalNotPermission = false; enableScroll()">Ok</button>
      </div>
    </div>
  </div>
</div>

<!--Modal para chamar garçom sem mesa-->
<div class="modal" [ngClass]="{'show': openModalCallCard}" *ngIf="this.company">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title tittle-modal" id="staticBackdropLabel" style="font-size: 17px;">Chamar garçom</h1>
      </div>
      <div class="modal-body" style="padding: 0;">
        <form (ngSubmit)="callWaiter(textObservationWaiter, numberInput)" style="text-align: center; width: 100%;">
          <div class="table-area" *ngIf="this.company.configs.cardConfig == 1">
            <span>Insira sua mesa</span>
            <input class="input-table" [(ngModel)]="numberInput" name="numberInput" type="number" style="margin-bottom: 12px;" #callWaitersCardInput maxlength="3"/>
            <span style="margin-bottom: 8px; color: orange;" *ngIf="this.numberInput == '0'">Mesa invalida</span>
          </div>
          <div class="observation-area">
            <span style="font-weight: 100;">Motivo da solicitação</span>
            <textarea class="text-area-call" placeholder="Limpar a mesa, trazer talheres, etc." maxlength="48" (input)="receivedEvent($event)" [(ngModel)]="textObservationWaiter" name="textObservationWaiter" #callWaitersCardObs></textarea>
            <div style="display: flex; font-size: 14px; color: #737373;padding-top: 2px;">
              <span class="d-flex" style="width: 20px; justify-content: flex-end;">{{ this.countText }}</span><span>/48</span>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn modal-btn-negative" (click)="openModalCallCard = false;">Cancelar</button>
            <button *ngIf="this.company.configs.cardConfig == 1" type="submit" class="btn modal-btn-positive" [disabled]="textObservationWaiter == '' || numberInput == '' || numberInput == '0'">Chamar</button>
            <button *ngIf="this.company.configs.cardConfig == 0" type="submit" class="btn modal-btn-positive" [disabled]="textObservationWaiter == ''">Chamar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="modal" [ngClass]="{'show': openModalCallTable}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title tittle-modal" style="font-size: 19px; padding-left: 55px;">Chamar garçom</h1>
        <div class="div-icon-table">
          <!-- <i style="margin-bottom: 5px;"><img src="assets/restaurante-3.png" style="width: 30px;" /></i> -->
          <i ><img src="assets/restaurante.png" style="width: 30px;" /></i>
          <span style="color: #636363;">{{ this.numberTable }}</span>
        </div>
      </div>
      <div class="modal-body" style="padding: 0;">
        <form (ngSubmit)="callWaiter(textObservationWaiter, '0')" style="text-align: center; width: 100%;">
          <div class="observation-area">
            <span style="font-weight: 100;">Motivo da solicitação</span>
            <textarea class="text-area-call" placeholder="Limpar a mesa, trazer talheres, etc." maxlength="48" (input)="receivedEvent($event)" [(ngModel)]="textObservationWaiter" name="textObservationWaiter" style="padding: 5px 10px 5px 10px;" #callWaitersCardObs></textarea>
            <div style="display: flex; font-size: 14px; color: #737373;padding-top: 2px;">
              <span class="d-flex" style="width: 20px; justify-content: flex-end;">{{ this.countText }}</span><span>/48</span>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn modal-btn-negative" (click)="openModalCallTable = false;">Cancelar</button>
            <button type="submit" class="btn modal-btn-positive" [disabled]="textObservationWaiter == ''">Chamar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
