
<app-top-menu-two *ngIf="showTopMenu"></app-top-menu-two>
<div style="max-width: 800px; margin: auto; height: 100vh;">
  <div class="navegation-header">
    <div class="navegation-header__icon" (click)="goback()">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </div>
    <div class="navegation-header__tittle">
      <h5>Carrinho</h5>
      <div class="cart-header__div-button" *ngIf="bag.length > 0">
        <button (click)="this.openModalTwo = true"><img src="assets/icons/lixeira-red.png" style="width: 15px;"/>Esvaziar</button>
      </div>
    </div>
  </div>
  <div class="body-cart">

    <div class="cart" >
      <div class="d-flex" style="flex-direction: column;">
        <!-- <div>
          <img [src]="logoCompany" style="width: 50px; border-radius: 25px;"/>
        </div>
        <div class="cart-header">
          <a href="#">{{this.nameEmpresa}}</a>
        </div> -->
        <ng-container *ngIf="this.verification != ''">
          <div style="display: flex; justify-content: space-around; align-items: center;">
            
            <div class="header-info" *ngIf="alterationString">
              <span style=" font-weight: 600; color: #636363;;">{{ this.nameIdentification }}</span>
              <i *ngIf="alterationString" style="display: flex; align-items: center; justify-content: center;"><img src="assets/icons/card-1.png" style="width: 35px; z-index: 99;"/><span class="number-param-command">{{ this.typeParam.numberParam }}</span></i>
            </div>
    
            <div class="header-info" *ngIf="!alterationString" style="gap: 7px;">
              <i *ngIf="!alterationString"><img src="assets/restaurante.png" style="width: 35px;" /></i>
              <span style="font-weight: 600; color: #636363;">{{ this.nameIdentification }}</span><span style=" font-weight: 600; color: #636363;">{{ this.typeParam.numberParam }}</span>
            </div>

            <div *ngIf="this.tableNumberSelected != ''" class="number-table" (click)="this.showModalCardAlterarion()">
              <span style="color: #636363;">MESA {{ this.tableNumberSelected }}</span>
              <button style="border: none; background-color: white;"><i [ngStyle]="{'color': !this.permissionRequest ? 'gray' : 'var(--primary-color)'}" class="fa-regular fa-pen-to-square" style=" font-size: 25px;"></i></button>
            </div>
          </div>

          <div class="name-customer" *ngIf="this.nameCustomer != ''" (click)="modalNameAlterarion()">
            <span style="margin-right: 5px;">{{ this.nameCustomer }}</span>
            <button style="border: none; background-color: white;"><i [ngStyle]="{'color': !this.permissionRequest ? 'gray' : 'var(--primary-color)'}" class="fa-regular fa-pen-to-square" style=" font-size: 20px;"></i></button>
          </div>

          <hr style="color: #ada9a9; margin-bottom: 3px; margin-top: 3px; width: 100%;"/>
        </ng-container>
      </div>
      <div class="body-image" *ngIf="bag.length == 0">
        <div class="body-image__content">
          <img src="assets/icons/cart-empty.png"/>
        </div>
      </div>

      <div class="cart-list-item" *ngIf="bag.length > 0">
          <div *ngFor="let item of this.bag; let i = index" class="box-product-cart">
            <div class="d-flex" style="width: 100%;">
              <div class="picture-company">
                <ng-container *ngIf="item && item.imagem_prod">
                    <img [src]="item.imagem_prod" alt="Imagem">
                    <img [src]="item.imagem_prod" class="img-zoom"  [class.zoom]="isZoom" alt="Imagem">
                </ng-container>
                <div style="position: absolute;z-index: 1101;">
                  <i class="fa-solid fa-x close-img" [class.active]="isZoom"></i>
                </div>
                <!-- <div class="button-edition" style="height: 0px;">
                  <button class="button-edition-prod" (click)="capturaId(item.id_product, item, i)"><i class="fa-solid fa-pen"></i></button>
                </div> -->
            </div>
              <div class="cart-list-item__line" >
                <div class="cart-list-item__description">
                  <div style="display: flex; flex-direction: column;" >
                    <span class="cart-list-item__description__name">{{ item.name }}</span>
                  </div>
                  <div class="cart-list-item__additional" *ngIf="item.categories.length != 0">
                    <div class="cart-list-item__category" *ngFor="let categoryAdd of item.categories">
                      <span>{{ categoryAdd.categoryName }}</span>
                      <div class="cart-list-item__additional__item" *ngFor="let adicional of categoryAdd.additional">
                        <ng-container *ngIf="categoryAdd.flavorCategory == 1">
                          <ng-container *ngIf="adicional.quantitySub == this.flavorQuantityatt[i]">
                            <span style="font-size: 13px; padding-left: 10px; color: #5d5d5d; padding-right: 5px;">{{ 1 }}x</span><span>{{ adicional.nameSub }}</span>
                          </ng-container>
                          <ng-container *ngIf="adicional.quantitySub != this.flavorQuantityatt[i]">
                            <span style="font-size: 13px; padding-left: 10px; color: #5d5d5d; padding-right: 5px;">{{ adicional.quantitySub }}/{{ this.flavorQuantityatt[i] }}</span><span>{{ adicional.nameSub }}</span>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="categoryAdd.flavorCategory == 0">
                          <span style="font-size: 13px; padding-left: 10px; color: #5d5d5d;">{{ adicional.quantitySub }}x </span><span>{{ adicional.nameSub }}</span>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="content-observation" *ngIf="item.observations != ''" style="font-size: 13px; color: #939393; font-weight: 600;">
                      <span class="observation-tittle">Obs:</span>
                      <div class="observation-text">{{ item.observations }}</div>
                  </div>
                  <div style="display: flex;justify-content: space-between;">
                    <span class="cart-list-item__description__price">{{ item.totalPrice | currency:'BRL' }}</span>
                    <div class="buttons-item-list">

                      <div class="counter">
                        <button class="button-down activated" #btnNegative *ngIf="item.quantity > 1">
                          <div class="btn-negative" style="margin-top: 2px;">
                            <div (click)="removeOneInCounter(item, i)">-</div>
                          </div>
                        </button>
                        <div class="icon-content" *ngIf="item.quantity == 1">
                          <i class="fa fa-trash" aria-hidden="true" (click)="showModalDelete(i)" style="font-size: 12px; margin-top: 2px; color: var(--primary-color); cursor: pointer;"></i>
                          <!-- <img src="assets/icons/lixeira.png" /> -->
                        </div>
                        <div class="counter-div">{{ item.quantity }}</div>
                        <button class="button-plus" [ngClass]="{'disabled': item.quantity >= 10}">
                          <div class="btn-positive" style="font-size: 22px;">
                            <div (click)="addOneInCounter(item, i)" >+</div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
</div>
<div class="cart-footer__fixed" *ngIf="bag.length > 0">
  <div class="cart-footer__info">
    <div class="cart-footer__button">
      <button [ngClass]="{'active' : this.activeButton}" [disabled]="!this.activeButton" (click)="Next()"><span>{{ this.nameButton }}</span><span>{{ this.total | currency:'BRL' }}</span></button>
    </div>
  </div>
</div>
  <app-bottom-menu [verification]="this.verification"></app-bottom-menu>


<!--Modal Deletar Item-->
<div class="modal" [ngClass]="{'show': openModalDelete}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Excluir Produto</h1>
      </div>
      <div class="modal-body">
        <div class="observation-area" style="border-top: 0px !important;">
          <span style="font-size: 17px;text-align: center; margin-bottom: 12px; font-weight: 100;">Deseja excluir este produto do seu carrinho?</span>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn modal-btn-negative" (click)="openModalDelete = false;">Fechar</button>
        <button type="button" class="btn modal-btn-positive" (click)="this.Delete(); openModalDelete = false">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<!--Modal confirmação limpeza de carrinho-->
<div class="modal" [ngClass]="{'show': openModalTwo}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom: 0;">
        <!-- <h1 class="modal-title fs-5" id="staticBackdropLabel">Esvaziar carrinho</h1> -->
        <i class="fa fa-trash" aria-hidden="true" style="font-size: 30px; color: var(--primary-color);"></i>
        <!-- <img src="assets/icons/recycle-bin.png" alt="pedeaqui" style="width: 40px;"> -->
      </div>
      <div class="modal-body" style="padding-top: 0;">
        <div class="d-flex justify-content-center">
          <!-- <img src="assets/logo/Gif/animation-lixeira.gif" alt="pedeaqui" style="width: 60px;"> -->
          <!-- <img src="assets/logo/logo-pedeaqui.png" alt="pedeaqui" style="width: 170px;"> -->
          <!-- <i class="fa-solid fa-trash-can" style="color: #652969;"></i> -->
        </div>
        <span style="font-size: 17px;text-align: center;">Deseja esvaziar seu carrinho?</span>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="modal-btn-negative" (click)="this.openModalTwo = false" >Não</button>
        <button type="button" class="modal-btn-positive" (click)="clearCart()">Sim</button>
      </div>
    </div>
  </div>
</div>

<!--Modal confimação de mesa para pedido comanda com mesa-->
<div class="modal" [ngClass]="{'show': openModalCard}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" style="font-size: 17px; color: var(--primary-color); text-align: center;" *ngIf="this.company.configs.activeName">Confirmar pedido</h1>
        <h1 class="modal-title" style="font-size: 17px; color: var(--primary-color); text-align: center;" *ngIf="!this.company.configs.activeName">Insira o número da sua mesa</h1>
      </div>
      <div class="modal-body" style="padding-bottom: 0;">
        <form (ngSubmit)="this.AssembleOrder(valueInputTable)" style="text-align: center; width: 100%;">
          <div class="box-infos-modal">
            <!-- <span style="font-size: 17px;text-align: center; margin-bottom: 12px; color: var(--primary-color);">Insira o número da sua mesa</span> -->

            <div class="box-info">
              <span style="padding-left: 0; margin-bottom: 3px; color: var(--primary-color);" *ngIf="this.company.configs.activeName">Insira sua mesa</span>
              <input class="input-table" [(ngModel)]="valueInputTable" name="valorInputModal" type="number" style="margin-bottom: 12px;" #inputConfirmTable/>
              <span style="margin-bottom: 8px; color: orange;" *ngIf="this.valueInputTable == 0">Mesa invalida</span>
            </div>

            <div class="box-info" style="margin-top: 10px;" *ngIf="this.company.configs.activeName">
              <span style="padding-left: 0; color: var(--primary-color); margin-bottom: 3px;">Insira seu nome</span>
              <input class="input-name" [(ngModel)]="valueInputName" name="valueInputName" placeholder="Ex: Carlos" maxlength="100" type="text"/>
            </div>

            <ng-container *ngIf="!this.company.configs.activeName">
              <div style="display: flex; flex-direction: column; align-items: center; margin-top: 3px;" *ngIf="this.valueInputTable != 0 && this.valueInputTable && this.company.configs.localConfirmation == 1">        
                <img src="assets/logo/Gif/animation-confirm.gif" alt="pedeaqui" style="width: 45px;">
                <span style="font-size: 17px;text-align: center; margin-bottom: 12px; color: var(--primary-color);">Deseja confirmar seu pedido?</span>
              </div>
            </ng-container>

            <ng-container *ngIf="this.company.configs.activeName">
              <div style="display: flex; flex-direction: column; align-items: center; margin-top: 3px;" *ngIf="this.valueInputTable != 0 && this.valueInputTable && this.company.configs.localConfirmation == 1 && this.valueInputName.length >= 3">        
                <img src="assets/logo/Gif/animation-confirm.gif" alt="pedeaqui" style="width: 45px;">
                <span style="font-size: 17px;text-align: center; margin-bottom: 12px; color: var(--primary-color);">Deseja confirmar seu pedido?</span>
              </div>
            </ng-container>

          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn modal-btn-negative" (click)="openModalCard = false;">Não</button>
            <button type="submit" class="btn modal-btn-positive" *ngIf="this.company.configs.activeName" [disabled]="!this.valueInputTable || this.valueInputName.length < 3">Sim</button>
            <button type="submit" class="btn modal-btn-positive" *ngIf="!this.company.configs.activeName" [disabled]="!this.valueInputTable">Sim</button>
          </div>

        </form>
        <!-- <input [(ngModel)]="valorInputTable" type="number" style="margin-bottom: 12px;" #inputConfirmTable/>
        <div class="observation-area" style="border-top: 0px !important;">

        </div> -->
      </div>
    </div>
  </div>
</div>

<!--Modal edição de mesa-->
<div class="modal" [ngClass]="{'show': openModalCardAlteration}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="staticBackdropLabel" style="font-size: 17px; color: var(--primary-color);">Alterar mesa</h1>
      </div>
      <div class="modal-body" style="padding: 1rem 0 0 0;">
        <form (ngSubmit)="alterationTableNumber(inputValueAlteration)" style="text-align: center; width: 100%;">
          <div style="display: flex; flex-direction: column; align-items: center;">
            <input class="input-table" [(ngModel)]="inputValueAlteration" name="valorInputModal" type="number" style="margin-bottom: 12px;" #inputAlterationTable/>
            <span style="margin-bottom: 8px; color: orange;" *ngIf="this.inputValueAlteration == '0'">Mesa invalida</span>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn modal-btn-negative" (click)="openModalCardAlteration = false;">Fechar</button>
            <button type="submit" class="btn modal-btn-positive" [disabled]="!this.inputValueAlteration">Confirmar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!--Modal edição de nome-->
<div class="modal" [ngClass]="{'show': openModalNameAlteration}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="staticBackdropLabel" style="font-size: 17px; color: var(--primary-color);">Alterar nome</h1>
      </div>
      <div class="modal-body" style="padding: 1rem 0 0 0;">
        <form (ngSubmit)=" alterationNameCustomer()" style="text-align: center; width: 100%;">
          <div class="box-info">
            <input class="input-name" [(ngModel)]="valueInputName" name="valueInputName" type="text" maxlength="100" placeholder="Insira seu nome"/>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn modal-btn-negative" (click)="openModalNameAlteration = false;">Fechar</button>
            <button type="submit" class="btn modal-btn-positive" [disabled]="this.valueInputName.length < 3">Confirmar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!--Modal inserir o nome-->
<div class="modal" [ngClass]="{'show': openModalName}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="staticBackdropLabel" style="font-size: 17px; color: var(--primary-color);">Insira seu nome</h1>
      </div>
      <div class="modal-body" style="padding: 1rem 0 0 0;">
        <form (ngSubmit)="this.confirmNameCustomer()" style="text-align: center; width: 100%;">
          <div class="box-info">
            <input class="input-name" [(ngModel)]="valueInputName" name="valueInputName" type="text" maxlength="100" placeholder="Insira seu nome"/>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn modal-btn-negative" (click)="openModalName = false;">Fechar</button>
            <button type="submit" class="btn modal-btn-positive" [disabled]="this.valueInputName.length < 3">Confirmar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!--Modal confirmação finalização de pedido-->
<div class="modal" [ngClass]="{'show': openModalConfirm}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel" style="font-size: 17px !important; color: var(--primary-color);">Confirme seu pedido</h1>
      </div>
      <div style="margin-top: 10px" class="box-info" *ngIf="this.company.configs.activeName && this.nameCustomer == ''">
        <span style="padding-left: 0; color: var(--primary-color);">Insira seu nome</span>
        <input class="input-name" [(ngModel)]="valueInputName" name="valueInputName" maxlength="100" type="text" placeholder="Ex: Carlos"/>
      </div>
      <div class="modal-body" style="gap: 10px;">
        <img src="assets/logo/Gif/animation-confirm.gif" alt="pedeaqui" style="width: 45px;">
        <span style="font-size: 17px;text-align: center; margin-bottom: 5px; color: var(--primary-color);">Deseja finalizar seu pedido?</span>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn modal-btn-negative" (click)="openModalConfirm = false;">Não</button>
        <button type="button" class="btn modal-btn-positive" *ngIf="!this.company.configs.activeName" (click)="this.sucessModalConfirm()">Sim</button>
        <button type="button" class="btn modal-btn-positive" *ngIf="this.company.configs.activeName" [disabled]="this.valueInputName.length < 3" (click)="this.sucessModalConfirm()">Sim</button>
      </div>
    </div>
  </div>
</div>
