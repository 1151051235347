import { Injectable, OnInit } from '@angular/core';
import { Pedido, PedidoClass, additional, categoryAdd } from 'src/app/Interfaces/pedido';
import { CacheService } from '../Cache/cache.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Produto } from 'src/app/Interfaces/produtos';

@Injectable({
  providedIn: 'root'
})
export class CartService implements OnInit {
  product!: Pedido;

  idTableCardSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  idTableCard$: Observable<string> = this.idTableCardSubject.asObservable();

  productEditionSubject: BehaviorSubject<Pedido> = new BehaviorSubject<Pedido>(this.product);
  productEdition$: Observable<Pedido> = this.productEditionSubject.asObservable();

  private nameCustomerSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public nameCustomer$: Observable<string> = this.nameCustomerSubject.asObservable();

  indexItem!: number | undefined;

  private counterSubject = new BehaviorSubject<number>(0);
  public counter$ = this.counterSubject.asObservable();

  public cartItems: Pedido[] = [];

  constructor(private cacheService: CacheService){}
  ngOnInit(): void {
    this.getCartItems();
  }

  public updateCounter(newCounter: number){
    this.counterSubject.next(newCounter)
  }

  public updateNameCustomer(newName: string){
    this.nameCustomerSubject.next(newName);
  }

  addToCart(item: Pedido) {
    const matchingProducts = this.cartItems.filter(product => product.id_product === item.id_product);

    let index = this.indexItem
    // this.indexItem$.subscribe((data) =>{
    //   index = data
    // })

    if(index == null || index == undefined)
    {
      if (matchingProducts.length > 0) {
        const matchingItem = matchingProducts.find(existingProduct => {
          if (item.observations !== '' && existingProduct.observations !== '') {
            return (
              item.observations === existingProduct.observations &&
              this.areAdditionalsEqual(item.categories, existingProduct.categories)
            );
          } else if (item.observations === '' && existingProduct.observations === '') {
            return this.areAdditionalsEqual(item.categories, existingProduct.categories);
          }
          return false;
        });

        if (matchingItem) {
          matchingItem.quantity += item.quantity;
          if(matchingItem.pizzaPrice == 0)
          {
            matchingItem.totalPrice = matchingItem.unitPrice * matchingItem.quantity;
          }
          else{
            matchingItem.totalPrice = matchingItem.totalPrice * matchingItem.quantity;
          }

        } else {
          this.cartItems.push(item);
        }
      } else {
        this.cartItems.push(item);
      }
    }
    else
    {
      this.cartItems[index] = item;
      this.indexItem = undefined
    }

    this.updateCounter(this.cartItems.length)
    this.cacheService.setItemLocal("product-cart", this.cartItems);
  }

  areAdditionalsEqual(additionals1: categoryAdd[], additionals2: categoryAdd[]): boolean {
    // Se o comprimento dos arrays for diferente, eles não são iguais
    if (additionals1.length !== additionals2.length) {
      return false;
    }

    // Verificar se todos os ids de additionals1 existem em additionals2 e vice-versa
    for (const category1 of additionals1) {
      const matchingCategory = additionals2.find(category2 => category2.id_category === category1.id_category);

      if (!matchingCategory) {
        return false;
      }

      if (!this.areAdditionalArraysEqual(category1.additional, matchingCategory.additional)) {
        return false;
      }
    }

    return true;
  }

  private areAdditionalArraysEqual(additionals1: additional[], additionals2: additional[]): boolean {
    for (const additional1 of additionals1) {
      if (!additionals2.some(additional2 => additional2.add_id_product === additional1.add_id_product)) {
        return false;
      }
    }

    for (const additional2 of additionals2) {
      if (!additionals1.some(additional1 => additional1.add_id_product === additional2.add_id_product)) {
        return false;
      }
    }

    return true;
  }

  removeToCart(i: number){
    this.cartItems.splice(i, 1)
    this.cacheService.setItemLocal("product-cart", this.cartItems)
    this.updateCounter(this.cartItems.length)
  }

  editItemCart(item: Pedido, index: number){
    this.cartItems[index] = item
    this.cacheService.setItemLocal("product-cart", this.cartItems)
    this.updateCounter(this.cartItems.length)
  }

  getCartItems() {
    this.cartItems = this.cacheService.getItemLocal("product-cart")
    if(this.cartItems == null)
    {
      this.cartItems = []
    }
    this.updateCounter(this.cartItems.length)
    return this.cartItems;
  }

  clearCart() {
    this.cartItems = [];
    this.updateCounter(this.cartItems.length)
    this.cacheService.setItemLocal("product-cart", this.cartItems = [])
  }
}
