import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { typeInterface } from 'src/app/Interfaces/company';
import { Cliente } from 'src/app/Interfaces/customer';
import { Fidelity } from 'src/app/Interfaces/fidelity';
import { OrderUser, Pedido } from 'src/app/Interfaces/pedido';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';

@Component({
  selector: 'app-fidelity',
  templateUrl: './fidelity.component.html',
  styleUrl: './fidelity.component.css'
})
export class FidelityComponent implements OnInit, AfterViewInit{
  private typeParam!: typeInterface;
  nameCompany: string = '';
  user!: Cliente;
  company!: Company;
  fidelityUser!: Fidelity;
  fidelityGet: boolean = false;
  percentageValue: number = 0;
  percentageCount: number = 0;
  userConfirmation: boolean = false;
  showLoading: boolean = false;
  showTopMenu: boolean = false;

  constructor(private router: Router,
    private connection: ConnectionService,
    private cacheService: CacheService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private authLayout: AuthLayoutComponent,
  ){
    this.connection.typeParam$.subscribe((newTypeParam) => {
      this.typeParam = newTypeParam
    })
  }

  ngOnInit(): void {
    this.connection.fidelity$.subscribe(
      (data) =>{
        this.fidelityUser = data;
        this.fidelityGet = true;
        if(this.fidelityUser.fidelityOption == 1){
          this.calcPercentageCount();
        }
        else{
          this.calcPercentageValue();
        }

    })
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })
    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
      if(!this.fidelityUser){
        this.getFidelity();
      }

    })
    this.connection.user$.subscribe((User) =>{
      this.user = User;
      if(!this.fidelityUser){
        this.getFidelity();
      }
    },
    () =>{
      if(this.cacheService.getItemLocal('user_key'))
      {
        this.user = this.cacheService.getItemLocal('user_key')
      }
    })

  }

  ngAfterViewInit(): void {
    this.checkScreenSize()
  }

  goBack(){
    this.router.navigate([this.nameCompany, 'user']);
    // if(!this.fidelityContent){
    //   this.router.navigate([this.nameCompany, 'user']);
    // }
    // else{
    //   this.close.emit(true);
    // }

  }

  @HostListener('window:resize', [])
  onWindowResize(event: any) {
    this.checkScreenSize();
  }

  //Verifica o tamanho da tela, esconde o Menu superior se necessario
  checkScreenSize(){
    if(window.innerWidth <= 846){

      this.showTopMenu = false;
    }
    else{
      this.showTopMenu = true;
    }
    this.authLayout.checkScreenSize();
  }

  //tipo fidelidade 1 é por pedido
  //tipo fidelidade 0 é por valor
  getFidelity(){
    if(this.company){
      if(this.user){
        if(!this.fidelityGet)
        {
          this.apiService.getFidelity(this.user.id_customer, this.company.company.id_company).subscribe(
            (data) =>{
              console.log(data)
              this.fidelityUser = data;
              this.fidelityGet = true;
              if(this.fidelityUser.fidelityOption == 1){
                this.calcPercentageCount();
              }
              else{
                this.calcPercentageValue();
              }
              console.log(this.fidelityUser);
            },
            (error) =>{
              console.log(error);
            }
          )
        }
      }
    }

  }

  calcPercentageCount(){
    this.percentageCount = (this.fidelityUser.ordersCount / this.fidelityUser.fidelityCount) * 100;
  }

  calcPercentageValue(){
    this.percentageValue = (this.fidelityUser.ordersValue / this.fidelityUser.fidelityValue) * 100;
  }

  direct(){
    this.showLoading = true
    this.apiService.requestCoupon(this.user.id_customer, this.company.company.id_company).subscribe(
      (data) =>{
        if(data.created){
          
          this.apiService.getUsuario(this.user.phone).subscribe((data) =>{this.connection.updateUser(data.response.customer)})
          this.connection.getFidelity(this.user.id_customer, this.company.company.id_company)
          setTimeout(() => {
            this.showLoading = false;
            this.router.navigateByUrl(`${this.nameCompany}/coupons`)
            this.toastr.success('Premio resgatado', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 4000,});
          }, 1000);
        }

      },
      (error) =>{
        console.log(error);
      }
    )
  }
  
}
