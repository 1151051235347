import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { additional, categoryAdd, OrderUser, Pedido } from 'src/app/Interfaces/pedido';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { OrderService } from 'src/app/shared/services/Order/order.service';
import { UserService } from 'src/app/shared/services/User/user.service';
import { Location } from '@angular/common';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { paymentForm, Pix } from 'src/app/Interfaces/order';
import { Subscription } from 'rxjs';
import { WebhookService } from 'src/app/shared/services/WeebHook/webhook.service';
import { Coupon } from 'src/app/Interfaces/coupon';
import { Cliente } from 'src/app/Interfaces/customer';

@Component({
  selector: 'app-order-confirmation-counter',
  templateUrl: './order-confirmation-counter.component.html',
  styleUrls: ['../order-confirmation/order-confirmation.component.css', "../../../assets/styles/global/_modal.scss"]
})
export class OrderConfirmationCounterComponent implements OnInit, AfterViewInit{
  //#region Variaveis Locais
    private webHookSubscription!: Subscription;
    openModal = false;
    showTopMenu = true;
    eventNavegation = false;
    openLoadingComponent = false;
    abilitButton = false;
    openModalPix = false;
    counterFinalizado = false;
    openCardLoading = false;
    openModalObservation = false;
    observationActive = false;
    hiddenCard: boolean = false;
    corretFormat!: boolean | undefined;
    toastCloseStoreActive = false;
    disableButton = false;
    openCouponView = false;
    desvinculationCoupon = false
    openModalFidelity = false;
    productsDiscount: Pedido[] = []
    couponSelected!: Coupon
    productPromotion: any;
    productInseringPromotion: any;
    modalPromotion = false;
    nameCompany: string = '';
    typeParam!: number;
    company!: Company;
    bag: Pedido[] = [];
    name!: string;
    phoneNumber!: string;
    document: string = '';
    user!: Cliente;
    subTotal: number = 0;
    // intervalId: any;
    paymentObject: paymentForm[] = []
    flavorQuantity: number[] = []
    orderObservation: string = '';
    countText = 0;

    //Objeto do pix
    pixObject: Pix = {
      createdAt: '',
      copyPaste: '',
      qrCode: '',
      id_order: '',
      value: 0,
    }

    //Objeto do pedido
    sacola: OrderUser = {
      id_companyFK: 0,
      id_customerFK: '',
      changeFor: '0',
      document_customer: '',
      orderType: 2,
      orderPrice: 0,
      orderObservation: '',
      orderStatus: '',
      paymentForm: this.paymentObject,
      onlinePay: 0,
      products: this.bag,
      coupons: false,
      couponType: 0,
      id_coupon: '0',
      discountValue: 0,
      deliveryFee: 0
    };

    //Sessão de observação
    observationSession = {
      checked: false,
      value: 0
    }
    
    //Objeto de metodo de recebimento
    methodReceiptSelected = {
      checked: true,
      value: 2
    };
  
    //Objeto de Tipo de pagamento
    typeMethodSelected = {
      checked: false,
      value: 0
    }
  
    //Objeto de Formas de pagamento
    paymentFormSelected = {
      checked: false,
      value: 0,
      name: ''
    }



  //#endregion

  //#region Inicializadores

  constructor(
    private cacheService: CacheService,
    private router: Router,
    private toastr: ToastrService,
    private orderService: OrderService,
    private cartService: CartService,
    private authLayout: AuthLayoutComponent,
    private connection: ConnectionService,
    private eventsService: EventsService,
    private userService: UserService,
    private apiService: ApiService,
    private location: Location,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private webHookService: WebhookService,
    ){
      this.connection.nameCompany$.subscribe((newNameCompany) => {
        this.nameCompany = newNameCompany;
      })

      this.eventsService.eventNavegation$.subscribe((newEvent) =>{
        this.eventNavegation = newEvent
      })

      this.connection.company$.subscribe((newCompany) =>{
        this.company = newCompany
        if(this.company.configs.configObservation == 0)
        {
          this.hiddenCard = true;
          this.observationOrder({ checked: true, value: 0, name: '' })
        }
      })

      this.connection.user$.subscribe((User) =>{
        if(User){
          this.user = User
        }
        else if(this.cacheService.getItemLocal('user_key')){
          this.connection.getUser();
        }
      })
      this.authLayout.checkScreenSize();
  }

  //Iniciado assim que o component é criado
  ngOnInit(): void {
    this.location.replaceState(`${this.nameCompany}/B`);

    this.disablePullToRefresh();

    this.bag = JSON.parse(JSON.stringify(this.orderService.bag));

    let count = 0;

    this.bag.forEach((product, index) =>{
      count = 0
      product.categories.forEach(categorie =>{
        let value = 0
        categorie.additional.forEach(additional =>{
          value += additional.quantitySub
          if(categorie.flavorCategory == 1)
          {
            count += additional.quantitySub
          }
        })
      })
      this.flavorQuantity.push(count)
    })

    this.montarPedido();

    this.webHookSubscription = this.webHookService.getUpdates().subscribe(
      (content: any) => {
        if(content.type == 5){
          const data = content.data
          console.log(data);
          if(this.pixObject.id_order){
            if(data.id_order == this.pixObject.id_order)
              if(data.status == 1){
                clearInterval(this.pixObject.id_interval)
                this.toastr.success('Pagamento Confirmado!', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 4000});
                this.router.navigate([this.nameCompany, 'order-details', this.pixObject.id_order])
              }
              else if(data.status == 6){
                clearInterval(this.pixObject.id_interval)
                this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado', { positionClass: 'toast-top-right', timeOut: 4000});
                this.router.navigate([this.nameCompany, 'pedidos'])
              }
          }

        }
      },
      (error) => {
        console.error('Erro ao receber mensagem do servidor WebHook:', error);
      }
    );
  }

  //Inicializado após o termino do carregamento DOM
  ngAfterViewInit(){
    setTimeout(() => {
      this.name = this.user.name
      this.phoneNumber = this.user.phone
    });
  }

  ngOnDestroy(): void {
    if(this.webHookSubscription) {
      this.webHookSubscription.unsubscribe();
    }
    clearInterval(this.pixObject.id_interval);
  }

  //#endregion

  //#region Funções de estilo (Style)

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    @HostListener('window:resize', [])
    onWindowResize(event: any) {
      this.checkScreenSize();
    }

    //Verifica o tamanho da tela, esconde o Menu superior se necessario
    private checkScreenSize(){
      if(window.innerWidth <= 846){

        this.showTopMenu = false;
      }
      else{
        this.showTopMenu = true;
      }
    }

    //Função responsavel para não mostrar o modal caso tenha ido editar o usuario
    alterationEvent(){
      this.eventsService.eventNavegationSubject.next(true)
      this.userService.editingUserSubject.next(true);
      this.router.navigate([this.nameCompany, 'user-data'])
    }

    //O primeiro modal a ser aberto, quando o component inicia
    showModal(){
      setTimeout(() => {
        this.openModal = true
      }, 100);

    }

    //Função que é chamada para fazer a verificação se todos campos estão requeridos para abilitar o botão de finalizar o pedido
    AbilitButton(){
      if(this.typeMethodSelected.checked)
      {
        if(this.paymentFormSelected.checked){
          if(this.corretFormat == true || this.corretFormat == undefined){
            this.abilitButton = true;
          }
          else{
            this.abilitButton = false;
          }
        }
        else{
          this.abilitButton = false;
        }
      }
      else{
        this.abilitButton = false;
      }
    }

    viewCoupon(event: any){
      if(event == true){
        this.openCouponView = true;
      }
      else{
        this.openCouponView = false;
        setTimeout(() => {
          window.scrollTo({
            behavior: 'instant' as ScrollBehavior,
            top: document.documentElement.scrollHeight,
            left: 0,
          });
        }, 10);

      }
    }

    showModalObservation(){
      this.openModalObservation = true;
    }

    confirmObservation(){
      this.openModalObservation = false
      this.sacola.orderObservation = this.orderObservation
      if(this.sacola.orderObservation?.length != 0){
        this.observationActive = true;
      }
      else{
        this.observationActive = false;
      }
    }

    cancelObservation(){
      this.openModalObservation = false;
      this.orderObservation = this.sacola.orderObservation

      if(!this.observationActive){
        this.sacola.orderObservation = '';
      }
    }

    valueTextArea(event: any){
      const inputElement = event?.target as HTMLInputElement;

      if(inputElement.value.length <= 200){
        this.orderObservation = inputElement.value
        this.countText = this.orderObservation.length;
      }
      else[
        inputElement.value = this.orderObservation
      ]

    }

    goback(): void{
      if(this.company.company.status == 1){
        this.router.navigate([this.nameCompany, 'carrinho']);
        this.eventsService.eventNavegationSubject.next(false)
      }
      else{
        this.router.navigate([this.nameCompany, 'B']);
        this.eventsService.eventNavegationSubject.next(false)
        this.eventsService.eventTypeReceiptSubject.next(0)
      }

    }

  //#endregion

  //#region Funções de Lógica (Logic)

    //#region Box Selecteds

      //Card de observação de local no modo Balcão
      observationOrder(data: { checked: boolean, value: number, name: string }){
        this.observationSession = data
        if(data.checked == false && data.value == 0){
          
          this.typeMethodSelected = data
          this.paymentFormSelected = data

          this.sacola.localization = ''
          this.sacola.paymentForm = []
          this.paymentObject = [];
        }

        this.sacola.localization = data.name;
        this.AbilitButton();
        
      }

      //Card do tipo de pagamento
      typePayment(data: { checked: boolean, value: number })
      {
        this.typeMethodSelected = data
        if(data.checked == false && data.value == 0)
        {
          const paymentEmpty = {checked: false, value: 0, name: ''};
          this.paymentFormSelected = paymentEmpty;
          // limpa a Forma de pagamento
          this.sacola.paymentForm = [];
          this.paymentObject = [];
          this.sacola.onlinePay = 0;
        }
        this.AbilitButton();

      }

      //Card da forma de pagamento
      formPayment(data: { checked: boolean, value: number, name: string, id_form: string})
      {

        this.paymentFormSelected = data
        if(data.checked == false && data.value == 0)
        {
          this.modalPromotion = false;
          this.sacola.paymentForm = []
          this.paymentObject = []
          this.bag = JSON.parse(JSON.stringify(this.orderService.bag));
          this.sacola.products = this.bag
          this.sacola.onlinePay = 0;
        }
        else{

          const form: paymentForm = {
            id_formpayment: data.id_form,
            changeFor: "0",
            onlinePay: this.typeMethodSelected.value,
            payStatus: 0,
            paymentName: this.paymentFormSelected.name,
            paymentForm: this.paymentFormSelected.value,
            paymentValue: this.sacola.orderPrice.toString()
          }

          if(data.value == 0){
            form.onlinePay = 1
            this.sacola.onlinePay = 1;
          }
          else{
            form.onlinePay = 0
            this.sacola.onlinePay = 0;
          }

          //add ele no array e add o array no pedido
          this.paymentObject.push(form)
          this.sacola.paymentForm = this.paymentObject

          if(this.company.configs.edgePix == 1){
            if(data.value == 2 || data.value == 0){
              this.promotionPayment(this.sacola)
            }
          }

        }

        this.AbilitButton();
      }

      //Card do troco
      changeFor(event: any){
        if(typeof event == 'number')
        {
          this.sacola.changeFor = event.toString()
          this.sacola.paymentForm[0].changeFor = event.toString()
        }
      }

    //#endregion

    //#region Pix

      //Quando o usuario seleciona o pix, função responsavel de montalo
      paymentPix(sacola: OrderUser){
        this.openCardLoading = true;
        this.orderService.OrderUserPaymentPix(sacola).subscribe(
          (data) =>{
            this.apiService.paymentPix(data).subscribe((dataPayment) =>{
              this.openModalPix = true;
              this.openCardLoading = false;

              this.pixObject = {
                id_order: data.id_order,
                createdAt: data.createdAt,
                copyPaste: dataPayment.pagamento,
                qrCode: 'data:image/png;base64,' + dataPayment.pagamentoQrCode,
                value: dataPayment.valorTotal
              }
              console.log(this.pixObject);
              this.startVerification(data.id_order, this.company.company.id_company);

            })
          },(error) =>{
            this.openCardLoading = false;

          });
      }

      //Envia uma requisição para o servidor webhook para fazer a verificação
      startVerification(id_order: string, id_company: number){
        this.apiService.startVerification(id_order, id_company).subscribe(
          () =>{
            console.log('O Servidor iniciou a verificação')
            this.pixObject.id_interval = setInterval(() => {
              this.getStatusOrder(id_order)
            }, 10000) 
          },
          (error) =>{
            console.log(error);
          }
        )
      }

      //Resgata a confirmação de pagamento pix
      getStatusOrder(id_order: string){
        this.apiService.getStatusOrder(id_order).subscribe(
          (data) =>{
            if(data.status == 1){
              clearInterval(this.pixObject.id_interval)
              this.toastr.success('Pagamento Confirmado!', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 4000});
              this.router.navigate([this.nameCompany, 'order-details', this.pixObject.id_order])
            }
            else if(data.status == 10){
              clearInterval(this.pixObject.id_interval)
              this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado', { positionClass: 'toast-top-right', timeOut: 4000});
              this.router.navigate([this.nameCompany, 'pedidos'])
            }
          },
          (error) =>{

          }
        )
      }

      //Fecha o contador quando o tempo termina
      finalizationCounter(event: any){
        this.counterFinalizado = event
      }

      //Volta para view de pedidos
      backOrders(){
        this.router.navigate([this.nameCompany, 'pedidos']);
      }

    //#endregion

    //#region Coupon

      //Quando o cupom selecionado é ativado
      coupomSelected(event: any){

        if(event.check){
          let coupon: Coupon = event.coupon
          if(coupon.couponType == 1 || coupon.couponType == 3){
            if(coupon.percent != 0)
              {
                const discontFormated = coupon.percent / 100;
                let discont;
                if(this.sacola.orderType == 5){
                  const orderPrice = this.sacola.orderPrice - this.sacola.deliveryFee;
                  discont = orderPrice * discontFormated;
                  this.sacola.orderPrice = orderPrice - discont + this.sacola.deliveryFee;
                }
                else{
                  discont = this.sacola.orderPrice * discontFormated;
                  this.sacola.orderPrice = this.sacola.orderPrice - discont;
                }
                this.sacola.discountValue = discont;
                this.sacola.id_coupon = coupon.id_coupon;
                this.sacola.coupons = true;
                this.sacola.couponType = 1;
            }
            else if(coupon.value != 0){
              let discont = coupon.value
              this.sacola.orderPrice = this.sacola.orderPrice - discont
              this.sacola.discountValue = discont;
              this.sacola.id_coupon = coupon.id_coupon
              this.sacola.coupons = true,
              this.sacola.couponType = 2
            }
            else if(coupon.deliveryFree == true){
              const desconto = this.sacola.deliveryFee
              this.sacola.discountValue = desconto
              this.sacola.id_coupon = coupon.id_coupon,
              this.sacola.coupons = true
              this.sacola.couponType = 3
              this.sacola.orderPrice = this.sacola.orderPrice - this.sacola.deliveryFee
              this.sacola.deliveryFee = 0
            }
          }
          else if(coupon.couponType == 2){
            this.productsDiscount = this.sacola.products.filter(prodOrder => coupon.discountProducts.includes(prodOrder.id_product.toString())).map(prodOrder => ({ ...prodOrder }));
          
            if (this.productsDiscount.length === 1) {
              this.couponSelected = coupon
              this.applyCouponDiscount({ coupon, product: this.productsDiscount[0] });
            } else if (this.productsDiscount.length > 1) {
              if(coupon.percent != 0){
                this.openModalFidelity = true;
                this.couponSelected = coupon;
              }
              else{
                this.couponSelected = coupon
                this.applyCouponDiscount({ coupon, product: this.productsDiscount[0] });
              }
  
            } 
          }
        }
        else{
          this.deslectedCupom();
        }
      }
  
      //Aplica o desconto de cupons do tipo produto 
      applyCouponDiscount(event: any) {
        const product = event.product;
        const coupon = event.coupon;
        const matchingProducts = this.sacola.products.filter(productOrder => productOrder.id_product === product.id_product);
  
        if(matchingProducts.length > 1){
  
          const matchingItem = matchingProducts.find(existingProduct => {
            if (product.observations !== '' && existingProduct.observations !== '') {
              return (
                product.observations === existingProduct.observations &&
                this.additionalsEqual(product.categories, existingProduct.categories)
              );
            } else if (product.observations === '' && existingProduct.observations === '') {
              return this.additionalsEqual(product.categories, existingProduct.categories);
            }
            return false;
          });
  
          if(matchingItem){
  
            const discount = this.calculateDiscount(matchingItem, coupon);
            this.applyDiscountToProduct(matchingItem, discount);
    
            this.sacola.discountValue = discount;
            this.sacola.id_coupon = coupon.id_coupon;
            this.sacola.coupons = true;
            this.sacola.couponType = 2;
            this.sacola.orderPrice = this.sacola.orderPrice - discount
            this.toastr.success(`Cupom aplicado`, 'Sucesso', { positionClass: 'toast-top-right', timeOut: 3000});
            console.log(this.couponSelected)
          }
        }
        else{
          const matchingItem = this.sacola.products.find(productOrder => productOrder.id_product == product.id_product)
  
          if(matchingItem){
            const discount = this.calculateDiscount(matchingItem, coupon);
            this.applyDiscountToProduct(matchingItem, discount);
    
            this.sacola.discountValue = discount;
            this.sacola.id_coupon = coupon.id_coupon;
            this.sacola.coupons = true;
            this.sacola.couponType = 2;
            this.sacola.orderPrice = this.sacola.orderPrice - discount
            this.toastr.success(`Cupom aplicado`, 'Sucesso', { positionClass: 'toast-top-right', timeOut: 3000});
          }
  
        }
        this.openModalFidelity = false;
      }
  
      //Aplica o disconto no produto
      private applyDiscountToProduct(matchingItem: any, discount: number) {
        if (matchingItem.quantity > 1) {
          const productDiscount = { ...matchingItem };
          productDiscount.totalPrice = matchingItem.totalPrice / matchingItem.quantity;
          productDiscount.oldPrice = productDiscount.totalPrice;
          productDiscount.discountValue = discount;
          productDiscount.totalPrice -= discount;
          productDiscount.discount = true;
          productDiscount.quantity = 1;
      
          const priceDiscount = matchingItem.totalPrice / matchingItem.quantity;
          matchingItem.totalPrice = priceDiscount * (matchingItem.quantity - 1);
          matchingItem.quantity -= 1;
      
          this.sacola.products.push(productDiscount);
        } else {
          matchingItem.oldPrice = matchingItem.totalPrice;
          matchingItem.totalPrice -= discount;
          matchingItem.discount = true;
          matchingItem.discountValue = discount;
        }
      }
  
      //Calcula quanto de desconto o produto recebera
      private calculateDiscount(matchingItem: any, coupon: any): number {
        if (coupon.percent != 0) {
          if(matchingItem.quantity > 1){
            const totalPrice = matchingItem.totalPrice / matchingItem.quantity
            return totalPrice * (coupon.percent / 100);
          }
          else{
            return matchingItem.totalPrice * (coupon.percent / 100);
          }
  
        } else if (coupon.value != 0) {
          return coupon.value;
        }
        return 0;
      }
      
      //faz a comparação dos adicionais nos produtos
      private additionalsEqual(additionals1: categoryAdd[], additionals2: categoryAdd[]): boolean {
        // Se o comprimento dos arrays for diferente, eles não são iguais
        if (additionals1.length !== additionals2.length) {
          return false;
        }
    
        // Verificar se todos os ids de additionals1 existem em additionals2 e vice-versa
        for (const category1 of additionals1) {
          const matchingCategory = additionals2.find(category2 => category2.id_category === category1.id_category);
    
          if (!matchingCategory) {
            return false;
          }
    
          if (!this.additionalArraysEqual(category1.additional, matchingCategory.additional)) {
            return false;
          }
        }
    
        return true;
      }
  
      //Faz a comparação as categorias de adicionais nos produtos
      private additionalArraysEqual(additionals1: additional[], additionals2: additional[]): boolean {
        for (const additional1 of additionals1) {
          if (!additionals2.some(additional2 => additional2.add_id_product === additional1.add_id_product)) {
            return false;
          }
        }
    
        for (const additional2 of additionals2) {
          if (!additionals1.some(additional1 => additional1.add_id_product === additional2.add_id_product)) {
            return false;
          }
        }
    
        return true;
      }
  
      //Quando o usuario cancela a seleção de produtos que ganharão o desconto
      cancelSelectCoupon(){
        this.desvinculationCoupon = true;
        this.openModalFidelity = false
        setTimeout(() => {
          this.desvinculationCoupon = false;
        }, 100);
      }
  
      //Desseleciona o cupom e retira suas propriedades no pedido
      deslectedCupom(){
        this.bag = JSON.parse(JSON.stringify(this.orderService.bag));
        this.sacola.products = this.bag
  
        let coupon!: Coupon;
        this.couponSelected = coupon
  
        this.productsDiscount = []
  
        if(this.sacola.discountValue)
          {
            this.sacola.orderPrice = this.sacola.orderPrice + this.sacola.discountValue;
            this.sacola.id_coupon = '0';
            this.sacola.coupons = false;
            this.sacola.discountValue = 0;
            this.sacola.couponType = 0;
          }
      }

    //#endregion

    //#region Promoção para pagamento Pix (Borda Pix)

      //Compara se os objetos são iguais, todos as propriedades e valores
      equalsObjects(obj1: any, obj2: any): boolean {
        if (typeof obj1 !== typeof obj2) {
            return false;
        }
    
        if (typeof obj1 === 'object') {
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);
    
            if (keys1.length !== keys2.length) {
                return false;
            }
    
            for (const key of keys1) {
                if (!this.equalsObjects(obj1[key], obj2[key])) {
                    return false;
                }
            }
            return true;
        }
        return obj1 === obj2;
      }

      //Detecta, se existe promoção para aqueles produtos
      promotionPayment(products: OrderUser){
        console.log(products)
        this.apiService.promotionPaymentForm(products).subscribe(
          (data) =>{
            console.log(data);
            if(!data.empty){
              this.productInseringPromotion = data.principalProducts
              this.productPromotion = data.promoProducts[0]
              console.log(this.productPromotion)
              this.modalPromotion = true;
            }
          },
          (error) =>{
            console.log(error);
          }
        )
      }

      //Faz a lógica de localizar e adicionar a promoção no produto
      promotionSelected(data:{productOrder: Pedido, productPromotion: any}){   
          for (const product of this.sacola.products) {
            if(this.equalsObjects(product, data.productOrder)){
              if(product.quantity > 1){

                const newProduct = JSON.parse(JSON.stringify(product));
                newProduct.quantity = 1;
                newProduct.totalPrice = newProduct.baseValue;       
                if (newProduct.flavorsValue) {
                  newProduct.flavorsValue = newProduct.baseFlavorsValue;
                }

                product.quantity = product.quantity - 1;
                product.totalPrice -= newProduct.baseValue;
                if (product.flavorsValue) {
                  product.flavorsValue -= newProduct.baseFlavorsValue;
                }

                this.sacola.products.push(newProduct);
                data.productPromotion.promotionPay = true;
                newProduct.categories.push(data.productPromotion);

                let count = 0
                this.sacola.products.forEach((product, index) =>{
                  count = 0
                  product.categories.forEach(categorie =>{
                    let value = 0
                    categorie.additional.forEach(additional =>{
                      value += additional.quantitySub
                      if(categorie.flavorCategory == 1)
                      {
                        count += additional.quantitySub
                      }
                    })
                  })
                  this.flavorQuantity.push(count)
                })


                break;
              }
              else{
                data.productPromotion.promotionPay = true;
                product.categories.push(data.productPromotion)
                break;
              }

            }
          }
          console.log(this.sacola.products);
          this.modalPromotion = false;

      }

      //Remove a borda Pix gratis
      removeEdge(){
        this.bag = JSON.parse(JSON.stringify(this.orderService.bag));
        this.sacola.products = this.bag
      }

    //#endregion

    //#region Montagem e finalização do pedido

      //Monta o objeto de pedido de usuario, tanto para Pedido em balcão quanto em delivery
      montarPedido(){
        this.sacola = {
          id_customerFK: this.user.id_customer,
          document_customer: this.document,
          changeFor: '0',
          id_companyFK: this.company.company.id_company,
          products: this.bag,
          orderType: 2,
          orderStatus: '1',
          paymentForm: this.paymentObject,
          orderObservation: '',
          onlinePay: 0,
          orderPrice: this.calcTotalOrder(),
          id_coupon: '0',
          discountValue: 0,
          coupons: false,
          couponType: 0,
          deliveryFee: 0,

        }
      }

      //Manda o pedido para o serviço e limpa os itens do carrinho
      finalizationOrder(){
        this.disableButton = true;
        this.apiService.verifiStatusCompany(this.company.company.id_company).subscribe(
          (data) =>{
            if(data[0].open){
              this.eventsService.eventNavegationSubject.next(false)
              this.eventsService.eventTypeReceiptSubject.next(0)

              if(this.sacola.onlinePay == 1){
                this.paymentPix(this.sacola);
              }
              else{
                this.orderService.OrderUser(this.sacola);
                this.router.navigate([this.nameCompany, 'processing']);
              }
        
              this.cartService.clearCart();
            }
            else{
              if(!this.toastCloseStoreActive)
                {
                  this.toastCloseStoreActive = true;
                  this.toastr.error('Desculpe, mas a loja já fechou!', 'Loja Fechada',
                  { positionClass: 'toast-top-right', timeOut: 0, tapToDismiss: true,}
                  ).onHidden.subscribe(() =>{ this.toastCloseStoreActive = false});
                }
    
                this.connection.Empresa(this.nameCompany, this.typeParam).subscribe(
                  (data) =>{
                    console.log('Status da loja atualizado');
                  },
                  (error) =>{
                    console.log('Erro inesperado', error)
                  }
                )
            }
          },
          (error) =>{

          })
      }

      //Calcula o total da ordem para ser montado o objeto e feito o request
      calcTotalOrder(){
        let total = 0
        this.bag.forEach(product => {
          total += product.totalPrice
        });

        this.subTotal = total

        return total
      }
    //#endregion

    //#region Formatação de documentos CPF - CNPJ

      formatarDocumento(event: any) {
        const newValue = event.target.value;

        const rawValue = newValue.replace(/\D/g, '');
        let formatted = this.formatarCPF(rawValue);

        if(rawValue.length == 0){
          this.abilitButton = true
          this.corretFormat = undefined
        }
        if(rawValue.length >= 1  && rawValue.length < 11)
        {
          this.abilitButton = false;
          this.corretFormat = false
        }
        else if(rawValue.length == 11){
          this.corretFormat = true
          this.AbilitButton()
        }
        else if(rawValue.length >= 12 && rawValue.length < 14){
          this.abilitButton = false;
          this.corretFormat = false;
        }
        else if(rawValue.length == 14){
          this.corretFormat = true
          this.AbilitButton()
        }


        if (rawValue.length <= 11) {
          formatted = this.formatarCPF(rawValue);
        } else {
          formatted = this.formatarCNPJ(rawValue);
        }
        this.document = formatted
        this.sacola.document_customer = this.document
      }

      formatarCPF(cpf: string): string {

        const areaCode = cpf.substring(0, 3);
        const firstPart = cpf.substring(3, 6);
        const secondPart = cpf.substring(6, 9);
        const treePart = cpf.substring(9, 11);
        if(cpf.length <= 2)
        {
          return `${areaCode}`;
        }
        else if(cpf.length >= 2 && cpf.length <= 5 )
        {
          return `${areaCode}.${firstPart}`
        }
        else if(cpf.length >= 5 && cpf.length <= 8 )
        {
          return `${areaCode}.${firstPart}.${secondPart}`
        }
        else
        {
          return `${areaCode}.${firstPart}.${secondPart}-${treePart}`
        }
      }

      formatarCNPJ(cnpj: string): string {

        const area1 = cnpj.substring(0, 2)
        const area2 = cnpj.substring(2, 5)
        const area3 = cnpj.substring(5, 8)
        const area4 = cnpj.substring(8, 12)
        const area5 = cnpj.substring(12, 14)



        if(cnpj.length <= 13)
        {
          return `${area1}.${area2}.${area3}/${area4}-`
        }
        else{
          return `${area1}.${area2}.${area3}/${area4}-${area5}`
        }
      }

    //#endregion

//#endregion

}
