<div class="modal" [ngClass]="{'show': modalShow}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title tittle-modal" style="font-size: 17px; color: var(--primary-color);" id="staticBackdropLabel">Pedido</h1>
        </div>
        <div class="modal-body">
            <div style="width: 100%;">
              <span class="span-modal-promotion">Selecione o produto que recebera o desconto.</span>
              <ng-container >
                <div class="order-content-modal">
                    <ng-container *ngFor="let item of this.productsOrder; let i = index">
                        <div class="order-content__info" [ngClass]="{'selected': this.checked[i] == true}" (click)="this.selectedProd({checked: this.checked[i], value: item }, i)">
                            <div class="picture-prod">
                              <img [src]="item.imagem_prod"/>
                            </div>
                            <div class="order-content__info__details">
                              <div class="box-name">
                                <span style="font-size: 14px; font-weight: 100;">{{item.name}}</span>
                                
                              </div>

                              <ng-container *ngIf="item.discount">
                                <div class="box-price">
                                  <span class="price-discount">{{ item.totalPrice | currency:'BRL' }}</span>
            
                                  <span class="price" [ngClass]="{'row-check': item.discount}">{{  item.oldPrice | currency:'BRL' }}</span>
                                  <div class="info-discount" *ngIf="this.coupon.value != 0">{{ this.coupon.value | currency: 'BRL' }}</div>
                                  <div class="info-discount" *ngIf="this.coupon.percent != 0">{{ this.coupon.percent }}%</div>
                                </div>


            
                              </ng-container>
                            </div>
                            <div class="div-chekcbox-promo">
                              <!-- <checkbox-radius [checkboxData]="{ checked: false, value: item}" (checkboxChange)="this.selected($event)"></checkbox-radius> -->
                              <checkbox-radius [checkboxData]="{ checked: this.checked[i], value: item}"></checkbox-radius>
                            </div>
                          </div>
                    </ng-container>
                </div>
              </ng-container>

              <span class="span-modal-promotion" style="color: #ad0202; margin-top: 10px; font-size: 13px;">*O cupom é valido apenas para um item.*</span>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn modal-btn-negative" (click)="cancelOperation()">Cancelar</button>
          <button type="button" class="btn modal-btn-positive" (click)="aceept()" [ngClass]="{'disabled': !this.productOrderSelected}">Ok</button>
        </div>
      </div>
    </div>
  </div>