import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Company, ConnectionService } from '../../services/Connection/connection.service';
import { AuthLayoutComponent } from '../layout/auth-layout/auth-layout.component';
import { CacheService } from '../../services/Cache/cache.service';
import { CartService } from '../../services/Cart/cart.service';
import { typeInterface } from 'src/app/Interfaces/company';
import { Cliente } from 'src/app/Interfaces/customer';

@Component({
  selector: 'app-top-menu-two',
  templateUrl: './top-menu-two.component.html',
  styleUrls: ['./top-menu-two.component.css']
})
export class TopMenuTwoComponent {
  @ViewChild('buttonSearch') buttonSeach!: ElementRef;
  @ViewChild('divInput', {static: false}) divInput!: ElementRef;
  @ViewChild('input') input!: ElementRef;
  @ViewChild('imgCompany') imgCompany!: ElementRef;
  @ViewChild('divIcon') divIcon!: ElementRef;
  @ViewChild('inputDesktop') inputDesktop!: ElementRef;
  @ViewChild('divBar') divBar!: ElementRef;
  @ViewChild('topMenu') topMenu!: ElementRef;

    //#region Variaveis locais

    private typeParam!: typeInterface;
    readOnlyState: boolean = false;
    nameCompany: string = '';
    scrollTopPosition: number = 0;
    img!: string;
    company!: Company
    nameEmpresa: string = "";
    verificationTable!: boolean;
    counterCart: number = 0;
    userConfirmation = false
    user!: Cliente;
  //#endregion

  //#region Inicializadores

    constructor(
      private changeDetectorRef: ChangeDetectorRef,
      private router: Router,
      private connection: ConnectionService,
      private authLayout: AuthLayoutComponent,
      private cacheService: CacheService,
      private cartService: CartService
    ){
      this.cartService.counter$.subscribe(newCount => {
        this.counterCart = newCount;
      });
      this.connection.company$.subscribe(company => {
        this.company = company
      })

      this.connection.nameCompany$.subscribe(newNameCompany => {
        this.nameCompany = newNameCompany
      })

      this.connection.typeParam$.subscribe((newTypeParam) => {
        this.typeParam = newTypeParam;
      })

      this.connection.user$.subscribe((data) =>{
        this.user = data;
      })

      if(this.typeParam.type == 1 || this.typeParam.type == 2){
        this.verificationTable = true
      }
      else{
        this.verificationTable = false
      }

    }

    //Chama as funções assim que o component é inicializado
    ngOnInit(): void {
      if(this.cacheService.getItemLocal('user_key')){
        this.userConfirmation = true;
      }

    }

    //Chama as funções assim que todo o DOM da aplicação foi carregado
    ngAfterViewInit(){
      this.connection.company$.subscribe((newImage) =>{
        this.img = newImage.company.logo;
      })
      this.changeDetectorRef.detectChanges();

      this.cartService.getCartItems()
    }

    validationReadOnly(){
      //-------------------------
      //TYPE INTERFACE
      // 0 = Delivery
      // 1 - Mesa
      // 2 - Comanda
      // 3 - Balcão
      //-----------------------
      if(this.company.configs.readOnly == 0){
        if(this.typeParam.type == 0){
          if(this.company.configs.deliveryReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
  
        }
        else if(this.typeParam.type == 1){
          if(this.company.configs.localReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
        else if(this.typeParam.type == 2){
          if(this.company.configs.localReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
        else if(this.typeParam.type == 3){
          if(this.company.configs.counterReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
      }
      else{
        this.readOnlyState = true;
      }
    }
}
