
<!--Cupom-->
<div *ngIf="!this.cupomselecionado && !this.openView" class="cupom-not-selected">
    <div class="box-coupon" [ngClass]="{'info': this.coupomNotValid || this.stringCoupomEmpty}">
        <div class="box-infos">
            <span style="color: var(--primary-color);" class="infos-title">Cupom</span>
            <span class="infos-content">Utilize seus cupons</span>
        </div>

        <button class="button-add" type="button" id="button-addon2" (click)="viewOpen()">Adicionar</button>
    </div>
</div>

<div class="cupom-selected" *ngIf="this.couponSelected && this.cupomselecionado && !this.openView">
    <div class="cupom-selected-content">
        <div class="group-img">
            <img src="./../../../../assets/icons/cupom.png" />
            <div class="span">
                <span class="span-coupon" *ngIf="this.couponSelected.value != 0">{{ this.couponSelected.value | currency:'BRL' }} de desconto</span>
                <span class="span-coupon" *ngIf="this.couponSelected.percent != 0">{{ this.couponSelected.percent }}% de desconto</span>
                <span class="span-coupon" *ngIf="this.couponSelected.deliveryFree">FRETE GRÁTIS</span>
                <span>Cupom aplicado</span>
            </div>
        </div>
        <button class="button-add" type="button" id="button-addon2" (click)="this.changeButton()">Alterar</button>
    </div>
</div>



<!--View para selecionar o cupom-->
<div *ngIf="this.openView" style="height: 100vh;" [ngClass]="{'response': this.showTopMenu}">
    <div class="box-header" style="max-width: 800px;">
      <div class="container">
        <div class="navegation-header d-flex align-items-center">
            <div class="box-icon" (click)="goBack()">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </div>
            <div class="box-title">
              <h3 style="margin-bottom: 0px;">Cupons</h3>
            </div>
        </div>
      </div>
      <div class="nav-coupons">
        <div class="nav-item" (click)="changeView(1)">
          <span class="nav-name" [ngClass]="{'active': this.viewSelected == 1}">Disponíveis</span>
          <i class="nav-bar" [ngClass]="{'active': this.viewSelected == 1}"></i>
        </div>
        <div class="nav-item" (click)="changeView(2)">
          <span class="nav-name" [ngClass]="{'active': this.viewSelected == 2}">Indisponíveis</span>
          <i class="nav-bar" [ngClass]="{'active': this.viewSelected == 2}"></i>
        </div>
      </div>
    </div>

    <div class="input-box-coupon" *ngIf="this.viewSelected == 1" style=" padding-top: 95px;">
      <div class="box-input" [ngClass]="{'info': this.coupomNotValid || this.stringCoupomEmpty}">
          <div class="input-infos">
              <input type="text" class="form-control" id="cupom" placeholder="Código do cupom" [ngModel]="this.cupomDigit" (ngModelChange)="this.cupomDigit= $event.toUpperCase(); this.checkChange($event)">
          </div>
          <button class="button-add" type="button" id="button-addon2" (click)="this.aplicationCoupom(this.cupomDigit, 1)">Adicionar</button>
      </div>
      <ng-container *ngIf="this.coupomNotValid">
          <span *ngIf="!this.minValue">{{ this.labelNotApliqued }}</span>
          <span *ngIf="this.minValue">Pedido minimo de {{ this.coupomNotValid.minValue | currency:'BRL' }}.</span>
      </ng-container>
      <span *ngIf="this.stringCoupomEmpty" style="font-size: 13px; color: rgb(177, 177, 177);">Por favor insira um cupom.</span>
    </div>

    <div style="margin-bottom: 50px; height: calc(100%);">

      <!--Cupons Validos-->
      <div class="box-coupons" *ngIf="this.viewSelected == 1" [ngClass]="{'align-center': this.notCouponsUserValid}" style="height: calc(100% - 174px);">
        <div class="div-not-cupons" *ngIf="this.notCouponsUserValid">
          <img src="assets/icons/cray.png" />
          <span>Você ainda não possui cupons</span>
        </div>
        <ng-container *ngIf="!this.notCouponsUserValid">
          <ng-container *ngFor="let coupon of this.couponsUserValid; let i = index">
            <div class="coupon-option" >
              <div class="bloqued" *ngIf="!coupon.valid.products || !coupon.valid.delivery || !coupon.valid.minValue" (click)="this.openInfoCoupon = !this.openInfoCoupon; this.disableScroll(); this.rulesCouponSelected = coupon">
                 <img src="../../../../assets/icons/padlock.png"/>
              </div>
              <div class="box-content" (click)="this.changeCheckBox({checked: true, value: coupon.coupon})">
                <div class="content">
                  <div class="coupon-icon">
                    <img src="./../../../../assets/icons/cupom.png" *ngIf="coupon.valid.products && coupon.valid.delivery && coupon.valid.minValue"/>
                    <img src="./../../../../assets/icons/cupom-disabled.png" *ngIf="!coupon.valid.products || !coupon.valid.delivery || !coupon.valid.minValue"/>
                  </div>
                  <div class="coupon">
                    <div class="flag-fidelity" *ngIf="coupon.couponFidelity == 1" [ngStyle]="{'background-color': coupon.valid.products && coupon.valid.delivery && coupon.valid.minValue ? 'var(--primary-color)' : '#a7a7a7'}">
                      <span>Fidelidade</span>
                    </div>
                    <ng-container *ngIf="coupon.couponValuePercent != 0" >
                      <div class="coupon-title" [ngClass]="{'disabled': !coupon.valid.products || !coupon.valid.delivery || !coupon.valid.minValue}">
                        {{ coupon.couponValuePercent }}% de desconto
                      </div>
                    </ng-container>
          
                    <ng-container *ngIf="coupon.couponValueCash != '0.00'">
                      <div class="coupon-title" [ngClass]="{'disabled': !coupon.valid.products || !coupon.valid.delivery || !coupon.valid.minValue}">
                      {{ coupon.couponValueCash | currency: 'BRL' }} de desconto
                      </div>
                    </ng-container>
    
                    <ng-container *ngIf="coupon.couponType == 3">
                      <div class="coupon-title" [ngClass]="{'disabled': !coupon.valid.products || !coupon.valid.delivery || !coupon.valid.minValue}">
                        FRETE GRÁTIS
                      </div>
                    </ng-container>
          
                    <div class="coupon-content">
                      <div class="coupon-rules" *ngIf="coupon.couponMinValue != '0.00'">
                        Pedido min {{ coupon.couponMinValue | currency: 'BRL' }}
                      </div>
                      <div class="coupon-rules" *ngIf="coupon.couponMinValue == '0.00'">
                        Sem pedido mínimo
                      </div>
                      <div class="coupon-expires" *ngIf="this.datesFormatted[i] != '1'" [ngStyle]="{'color': coupon.valid.products && coupon.valid.delivery && coupon.valid.minValue ? 'var(--primary-color)' : 'gray'}">
                        Expira em {{ this.datesFormatted[i] }} dias
                      </div>
                      <div class="coupon-expires" *ngIf="this.datesFormatted[i] == '1'" [ngStyle]="{'color': coupon.valid.products && coupon.valid.delivery && coupon.valid.minValue ? 'var(--primary-color)' : 'gray'}">
                        Expira hoje
                      </div>
                    </div>
                  </div>
                </div>
                <div class="coupon-more">
                  <checkbox-radius [checkboxData]="{checked: false, value: coupon.coupon}" [uncheck]="this.alteration" disabled></checkbox-radius>
                </div>
              </div>
              <div class="rules-coupon" (click)="openRulesCoupon(coupon)">
                  <span>Ver Detalhes</span>
              </div>
  
            </div>
          </ng-container>
        </ng-container>
      </div>

      <!--Cupons Invalidos-->
      <div class="box-coupons" style="padding-top: 120px;" *ngIf="this.viewSelected == 2" [ngClass]="{'align-center': this.notCouponsUserInvalid}">
        <div class="div-not-cupons" *ngIf="this.notCouponsUserInvalid">
          <img src="assets/icons/ghost.png" style="width: 120px;"/>
          <span>Tudo vazio por aqui</span>
        </div>
        <ng-container *ngIf="!this.notCouponsUserInvalid">
          <ng-container *ngFor="let coupon of this.couponsUserInvalid; let i = index">
            <div class="coupon-option">
              <div class="box-content invalid">
                <div class="content">
                  <div class="coupon-icon">
                    <img src="./../../../../assets/icons/cupom-disabled.png"/>
                  </div>
                  <div class="coupon">
                    <div class="flag-fidelity" *ngIf="coupon.couponFidelity == 1" style="background-color: #a7a7a7;">
                      <span>Fidelidade</span>
                    </div>
                    <ng-container *ngIf="coupon.couponValuePercent != 0">
                      <div class="coupon-title disabled">
                        Desconto {{ coupon.couponValuePercent }}%
                      </div>
                    </ng-container>
          
                    <ng-container *ngIf="coupon.couponValueCash != '0.00'">
                      <div class="coupon-title disabled">
                        Desconto {{ coupon.couponValueCash | currency: 'BRL' }}
                      </div>
                    </ng-container>

                    <ng-container *ngIf="coupon.couponType == 3">
                      <div class="coupon-title disabled">
                        FRETE GRÁTIS
                      </div>
                    </ng-container>
          
                    <div class="coupon-content">
                      <div class="coupon-rules" *ngIf="coupon.couponMinValue != '0.00'">
                        Pedido min {{ coupon.couponMinValue | currency: 'BRL' }}
                      </div>
                      <div class="coupon-rules" *ngIf="coupon.couponMinValue == '0.00'">
                        Sem pedido mínimo
                      </div>
                      <div class="coupon-expires"  style="color: gray;">
                        Expirado
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rules-coupon" (click)="openRulesCoupon(coupon)">
                <span>Ver Detalhes</span>
            </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>





</div>

<!--Detalhes de cupom bloqueado-->
<div class="box-off-canva" [ngClass]="{'open': this.openInfoCoupon}" (click)="this.openInfoCoupon = false; this.enableScroll()"></div>
<div class="offcanvas offcanvas-bottom" tabindex="-1" [ngClass]="{'show': this.openInfoCoupon}" style="height: fit-content; z-index: 120;">
  <div class="header-info">
    <div></div>
  </div>
  <div class="offcanvas-body">
    <div class="content-offCanva" *ngIf="this.rulesCouponSelected">
      <span class="title-offCanva">Cupom Bloqueado</span>
      <span class="info-offCanva" *ngIf="!this.rulesCouponSelected.valid.products">Este cupom não se aplica aos itens no seu carrinho.</span>
      <span class="info-offCanva" *ngIf="!this.rulesCouponSelected.valid.delivery">Cupom valido apenas para entrega.</span>
      <span class="info-offCanva" *ngIf="!this.rulesCouponSelected.valid.minValue">O valor mínimo do pedido não foi atingido.</span>
    </div>
    <div class="button-offCanva">
      <button (click)="this.openInfoCoupon = false; this.enableScroll()">Fechar</button>
    </div>
  </div>
</div>


<!--Detalhes do cupom-->
<div class="box-off-canva" [ngClass]="{'open': this.openRuleCoupon}" (click)="this.openRuleCoupon = false; this.enableScroll()"></div>
<div class="offcanvas offcanvas-bottom" tabindex="-1" [ngClass]="{'show': this.openRuleCoupon}" style="height: fit-content; z-index: 120;">
  <div class="header-info">
    <div></div>
  </div>
  <div class="offcanvas-body" style="padding-top: 5px" *ngIf="this.rulesCouponSelected">
    <div class="content-offCanva" style="gap: 10px;">
      <span class="title-offCanva">Detalhes do Cupom</span>
      <div class="box-rules">
        <ul class="list-rules">

          <!--Tipo do Desconto-->
          <ng-container *ngIf="this.rulesCouponSelected.couponType == 1">
            <li class="row-rules" *ngIf="this.rulesCouponSelected.couponValuePercent != 0"><b class="discount-detail">{{ this.rulesCouponSelected.couponValuePercent }}% de desconto</b> no pedido</li>
            <li class="row-rules" *ngIf="this.rulesCouponSelected.couponValueCash != '0.00'"><b class="discount-detail">{{ this.rulesCouponSelected.couponValueCash | currency:'BRL' }} de desconto</b> pedido</li>
          </ng-container>
          <ng-container *ngIf="this.rulesCouponSelected.couponType == 2">
            <li class="row-rules" *ngIf="this.rulesCouponSelected.couponValuePercent != 0"><b class="discount-detail">{{ this.rulesCouponSelected.couponValuePercent }}% de desconto</b> em produtos</li>
            <li class="row-rules" *ngIf="this.rulesCouponSelected.couponValueCash != '0.00'"><b class="discount-detail">{{ this.rulesCouponSelected.couponValueCash | currency:'BRL' }} de desconto</b> em produtos</li>
          </ng-container>
          <li class="row-rules" *ngIf="this.rulesCouponSelected.couponType == 3"><b class="discount-detail">FRETE GRÁTIS</b></li>

          <!--Tipo de utilização-->
          <li class="row-rules" *ngIf="this.rulesCouponSelected.couponLimitUse >= 1">Pode ser utilizado {{ this.rulesCouponSelected.couponLimitUse + 1 }} vez</li>
          <li class="row-rules" *ngIf="this.rulesCouponSelected.couponLimitUse == 0">Pode ser utilizado uma vez</li>

          <!--Pedido Minimo-->
          <li class="row-rules" *ngIf="this.rulesCouponSelected.couponMinValue != '0.00'">Pedido minímo de <b style="color: var(--primary-color);">{{ this.rulesCouponSelected.couponMinValue | currency:'BRL' }}</b></li>
          <li class="row-rules" *ngIf="this.rulesCouponSelected.couponMinValue == '0.00'">Sem pedido mínimo</li>

          <!--Data de expiração-->
          <li class="row-rules" *ngIf="!this.rulesCouponSelected.expired">Expira em <b style="color: var(--primary-color);">{{ this.rulesCouponSelected.expiresFormatted }}</b></li>
          <li class="row-rules" *ngIf="this.rulesCouponSelected.expired">Expirou em <b style="color: var(--primary-color);">{{ this.rulesCouponSelected.expiresFormatted }}</b></li>
        </ul>
      </div>
    </div>
    <div class="button-offCanva">
      <button (click)="this.openRuleCoupon = false; this.enableScroll()">Fechar</button>
    </div>
  </div>
</div>