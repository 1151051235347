<app-top-menu-two *ngIf="this.showTopMenu"></app-top-menu-two>
<div class="my-content" [ngClass]="{'padding-none': this.openCouponView}">
  <div>

    <!--#region Header-->
      <div class="navegation-header" [ngClass]="{'close': this.openCouponView}">
        <div class="navegation-header__icon" (click)="goback()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </div>
        <div class="navegation-header__tittle">
          <h3>Confirmar Pedido</h3>
        </div>
      </div>

      <div class="box-user" [ngClass]="{'close': this.openCouponView}">
        <div class="user-detail" *ngIf="this.user">
          <span class="user-detail__two">{{this.name}}</span>
          <span class="user-detail__tree">{{this.phoneNumber}}</span>
        </div>
        <div class="user-detail__div-button">
          <button (click)="alterationEvent()"><i class="fa-solid fa-pen"></i>Editar</button>
        </div>
      </div>
    <!--#endregion-->

    <!--#region Pedido-->
      <div class="box" [ngClass]="{'close': this.openCouponView}">
        <div class="box-tittle">
          <div style="font-size: 14px;">Seu pedido</div>
        </div>
        <div class="order">
          <ng-container  *ngFor="let item of this.bag; let i = index">
            <div class="order-content">
              <div class="order-content__info">
                <div class="picture-prod">
                  <img [src]="item.imagem_prod" />
                </div>
                <div class="order-content__info__details">
                  <div style="font-size: 14px; font-weight: 100;" class="title-product-order">
                    <div style="font-weight: 400;">{{ item.quantity }}x {{item.name}}</div>
                  </div>
                  <div class="order-item__additional" >
                    <div class="order-item__category" *ngFor="let categoryAdd of item.categories">
                      <span style="font-weight: 100;">{{ categoryAdd.categoryName }}</span>
                      <div class="order-item__additional__item" *ngFor="let adicional of categoryAdd.additional">
                        <ng-container *ngIf="categoryAdd.flavorCategory == 1">
    
                          <ng-container *ngIf="adicional.quantitySub == this.flavorQuantity[i]">
                            <span style="font-weight: 100; font-size: 13px; padding-left: 10px; color: #5d5d5d; padding-right: 5px;">{{ 1 }}x</span><span style="font-weight: 100;">{{ adicional.nameSub }}</span>
                          </ng-container>
                          <ng-container *ngIf="adicional.quantitySub != this.flavorQuantity[i]">
                            <span style="font-weight: 100; font-size: 13px; padding-left: 10px; color: #5d5d5d; padding-right: 5px;">{{ adicional.quantitySub }}/{{ this.flavorQuantity[i] }}</span><span style="font-weight: 100;">{{ adicional.nameSub }}</span>
                          </ng-container>
    
                        </ng-container>
                        <ng-container *ngIf="categoryAdd.flavorCategory == 0">
                          <div>
                            <span style="font-weight: 100; font-size: 13px; padding-left: 10px; color: #5d5d5d;">{{ adicional.quantitySub }}x </span><span style="font-weight: 100;">{{ adicional.nameSub }}</span>
                            <button style="margin-left: 20px; border: none; background-color: transparent; color: red;" *ngIf="categoryAdd.promotionPay" (click)="removeEdge()">Remover</button>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="item.observations != ''" style="font-size: 13px; color: #939393; max-width: 200px;">
                    <span>Obs: </span><span style="word-wrap: break-word;">{{ item.observations }}</span>
                  </div>
                  <div class="info__detail__price">
                    <ng-container *ngIf="item.discount">

                      <span class="price-discount">{{ item.totalPrice | currency:'BRL' }}</span>

                      <span class="price" [ngClass]="{'row-check': item.discount}">{{  item.oldPrice | currency:'BRL' }}</span>
                      <ng-container *ngIf="this.couponSelected">
                        <div class="info-discount" *ngIf="this.couponSelected.value != 0">{{ this.couponSelected.value | currency: 'BRL' }}</div>
                        <div class="info-discount" *ngIf="this.couponSelected.percent != 0">{{ this.couponSelected.percent }}%</div>
                      </ng-container>


                    </ng-container>
                    <ng-container *ngIf="!item.discount && !item.productPromo">
                      <span class="price">{{ item.totalPrice | currency:'BRL' }}</span>
                    </ng-container>
                    <ng-container *ngIf="item.productPromo">
                      <span class="price" style="color: #07ca07;">Brinde</span>
                    </ng-container>
                  </div>
                </div>
              </div>
    
            </div>
          </ng-container>

          <span *ngIf="this.observationActive" style="color: gray; font-size: 14px; word-wrap: break-word; padding: 5px 0 5px 12px;">Obs: {{ this.sacola.orderObservation }}</span>
          <!-- <hr> -->
          <div class="order-box-button" (click)="this.showModalObservation()" *ngIf="this.company.configs.orderObservationActive == 1">
            <span *ngIf="!this.observationActive">Adicionar observação</span>
            <span *ngIf="this.observationActive">Editar observação</span>
          </div>
        </div>
      </div>
    <!--#endregion-->

    <!--#region Boxs Selecteds-->
      <app-observation-card [ngClass]="{'close': this.openCouponView}" (optionSelected)="observationOrder($event)" *ngIf="!this.hiddenCard"></app-observation-card>

      <method-card [ngClass]="{'close': this.openCouponView}" [methodReceipt]="this.methodReceiptSelected" (optionSelected)="typePayment($event)" *ngIf="this.observationSession.checked"></method-card>

      <app-payment-form-card [ngClass]="{'close': this.openCouponView}" [typeMethodPayment]="this.typeMethodSelected" [methodReceipt]="this.methodReceiptSelected" (optionSelected)="formPayment($event)" *ngIf="this.typeMethodSelected.checked"></app-payment-form-card>

      <app-form-change [ngClass]="{'close': this.openCouponView}" *ngIf="this.paymentFormSelected.value == 1" (change)="changeFor($event)"></app-form-change>
    
    <!--#endregion-->

    <hr [ngClass]="{'close': this.openCouponView}"/>
    <app-cupom (view)="viewCoupon($event)" (coupon)="this.coupomSelected($event)" [order]="this.sacola" [desvinculation]="this.desvinculationCoupon"></app-cupom>
    <div class="box-cpf" id="conteudo"  style="margin: auto; max-width: 450px; padding: 0.5rem;" *ngIf="this.company.configs.documentOption == 1" [ngClass]="{'close': this.openCouponView}">
      <span>Documento</span>
      <input class="form-control" [ngClass]="{'is-valid': this.corretFormat == true, 'is-invalid': this.corretFormat == false}" [(ngModel)]="document" (input)="formatarDocumento($event)" name="document" placeholder="Digite seu CPF/CNPJ"   style="width: 100%;" maxlength="18">
    </div>

    <!--#region Footer-->
      <div class="price-total-order" [ngClass]="{'close': this.openCouponView}">
        <div class="total">
          <div class="summary-total">
            <div class="total-value">
              <div class="total-span">Subtotal</div>
              <div class="total-span">{{ this.subTotal | currency:'BRL' }}</div>
            </div>
          </div>
          <div class="total-value" *ngIf="this.sacola.discountValue && this.sacola.couponType != 3">
            <div style="font-size: 13px">Desconto</div>
            <div class="total-span" style="color: #09a509; font-size: 13px;">-{{ this.sacola.discountValue | currency:'BRL' }}</div>
          </div>
          <div class="div-total-value">
            <div class="content-total-value">
              <div>Total</div>
              <div>{{ this.sacola.orderPrice | currency:'BRL' }}</div>
            </div>
          </div>
        </div>
      </div>
    <!--#endregion-->
  </div>

  <div class="box-button" [ngClass]="{'close': this.openCouponView}">
    <button (click)="finalizationOrder()" [disabled]="this.disableButton" [ngClass]="{'disabled-positive': !this.abilitButton}">Finalizar pedido</button>
  </div>
</div>

<app-modal-promotion *ngIf="this.modalPromotion" [modalShow]="this.modalPromotion" [productsPromotion]="this.productPromotion" [productsOrder]="this.productInseringPromotion" (promotion)="this.promotionSelected($event)"></app-modal-promotion>
<app-loading-processing-order *ngIf="this.openLoadingComponent" [startComponent]="this.openLoadingComponent"></app-loading-processing-order>
<app-card-loading *ngIf="this.openCardLoading" [openCard]="true" [labelPerson]="'Aguarde enquanto geramos seu pix...'"></app-card-loading>
<app-modal-fidelity *ngIf="this.openModalFidelity" [modalShow]="this.openModalFidelity" [coupon]="this.couponSelected" [productsOrder]="this.productsDiscount" (cancel)="cancelSelectCoupon()" (selected)="applyCouponDiscount($event)"></app-modal-fidelity>

<!--Modal Pix-->
<div class="modal" [ngClass]="{'show': openModalPix}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" style="font-size: 16px;" id="staticBackdropLabel">Pagamento Pix</h1>
      </div>
      <div class="modal-body" style="gap: 10px;">
        <div class="counter" *ngIf="!this.counterFinalizado">
          <i class="fa-solid fa-stopwatch" style="font-size: 20px; color: var(--primary-color);"></i>
          <app-counter-timing *ngIf="this.pixObject.createdAt != ''" [dateTimeTransaction]="this.pixObject.createdAt" (finalizionCounter)="this.finalizationCounter($event)"></app-counter-timing>
        </div>
        <span class="text-information">Copie o código Pix ou leia o Qrcode, e faça o pagamento no seu banco, antes do tempo expirar.</span>
        <div class="img-qrCode">
            <img [src]="this.pixObject.qrCode" alt="QR Code" style="width: 100%; height: 100%;"/>
        </div>
        <div class="input-pix">
          <input [value]="this.pixObject.copyPaste" disabled/>
        </div>
        <app-copy-button style="width: 100%;" [valueInput]="this.pixObject.copyPaste"></app-copy-button>

        <div class="button-router">
          <button (click)="backOrders()">Acompanhar pedido</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal Observação-->
<div class="modal" [ngClass]="{'show': openModalObservation}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" style="font-size: 16px;" id="staticBackdropLabel">Observação</h1>
      </div>
      <div class="modal-body">
        <div class="modal-body-observation">
          <textarea not-emoji class="form-control text-area-observation" [value]="this.orderObservation" (input)="this.valueTextArea($event)"  placeholder="Adicione uma observação ao seu pedido:"></textarea>
          <div style="display: flex; font-size: 14px; color: #737373; padding-top: 2px;">
            <span class="d-flex" style="width: 20px; justify-content: flex-end;">{{ this.countText }}</span><span>/200</span>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn modal-btn-negative" (click)="this.cancelObservation()">Cancelar</button>
        <button type="button" class="btn modal-btn-positive" (click)="this.confirmObservation()">Confirmar</button>
      </div>
    </div>
  </div>
</div>
