import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { typeInterface } from 'src/app/Interfaces/company';
import { Cliente } from 'src/app/Interfaces/customer';
import { Fidelity } from 'src/app/Interfaces/fidelity';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css', "../../../assets/styles/global/_modal.scss"]
})
export class UserViewComponent implements OnInit{

  @ViewChild('inputPhone') inputPhone!: ElementRef;
  @ViewChild('inputName') inputName!: ElementRef;

  existingUser = false;
  existingUserData: any;
  openLogOut: boolean = false;
  user!: Cliente;
  alterationButton: boolean = false;
  nameCompany: string = '';
  userConfirmation = false;
  name: string = '';
  phoneNumber: string = '';
  id_customer: string = '';
  alterationUser = false;
  lengthInputName: any;
  lengthInputPhone = false;
  company!: Company;
  corretFormatPhoneNumber: any;
  phoneNumberPattern = /^\(\d{2}\)\d{5}\-\d{4}$/;
  fidelityUser!: Fidelity;
  fidelityGet: boolean = false;
  showTopMenu: boolean = false;
  typeParam!: typeInterface;

  constructor(
    private authLayout: AuthLayoutComponent,
    private toastr: ToastrService,
    private router: Router,
    private connection: ConnectionService,
    private cacheService: CacheService,
    private apiService: ApiService
  ){
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })

    this.connection.typeParam$.subscribe((newTypeParam) => {
      this.typeParam = newTypeParam
    })

    this.connection.fidelity$.subscribe((data) =>{
      this.fidelityUser = data;
      this.fidelityGet = true
    })

    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
    })

    this.connection.user$.subscribe((User) =>{
      if(User)
      {
        this.user = User
        this.userConfirmation = true
      }
    })
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.checkScreenSize();
  }


//#region Log in User

  getFidelity(){
    if(this.company){
      if(this.user){
        this.apiService.getFidelity(this.user.id_customer, this.company.company.id_company).subscribe(
          (data) =>{
            this.fidelityUser = data;
            this.fidelityGet = true
            console.log(this.fidelityUser);
          },
          (error) =>{
            console.log(error);
          }
        )
      }
    }

  }


  @HostListener('window:resize', [])
  onWindowResize(event: any) {
    this.checkScreenSize();
  }

  //Verifica o tamanho da tela, esconde o Menu superior se necessario
  checkScreenSize(){
    if(window.innerWidth <= 846){

      this.showTopMenu = false;
    }
    else{
      this.showTopMenu = true;
    }
    this.authLayout.checkScreenSize();
  }

  //Assim que confirmado ele monta os objetos necessarios com os valores dos campos
  submitForm(){
    let user = this.cacheService.getItemLocal('user_key')
    let userAddress = this.cacheService.getItemLocal('Address_Saved')
    let cliente: Cliente;
    
    if(this.existingUserData){
      if(this.existingUserData.response.customer.name != this.name || this.existingUserData.response.customer.phone != this.phoneNumber)
        {
          this.alterationUser = true;
          if(this.id_customer != this.existingUserData.response.customer.id_customer){
            this.id_customer = this.existingUserData.response.customer.id_customer
          }
      }
      cliente = {
        name: this.name,
        phone: this.phoneNumber,
        id_customer: this.id_customer,
        document: ''
      }
    }
    else{
      cliente = {
        name: this.name,
        phone: this.phoneNumber,
        id_customer: '',
        document: ''
      } 
    }


    if(this.correctNumberPhone(this.phoneNumber)){
      if(this.existingUser == false)
        {
          this.apiService.postUsuario(cliente).subscribe(
            (data) => {
              console.log('Usuario cadastrado com sucesso');
              this.id_customer = data.response.Customer.id_customer

              const userKey = {
                phone: this.phoneNumber,
                id_customer: this.id_customer,
                name: this.name
              }
    
              if(user){
                if(data.response.Customer.id_customer != user.id_customer){
                  this.cacheService.removeItemLocal('Address_Saved')
                  this.cacheService.setItemLocal('user_key', userKey)
                }
              }
              else{
                this.cacheService.setItemLocal('user_key', userKey)
              }

              if(userAddress)
              {
                this.cacheService.removeItemLocal('Address_Saved')
              }
    
              this.connection.updateUser(data.response.Customer)

              this.toastr.success('Usuário cadastrado', 'Successo', { positionClass: 'toast-top-right', timeOut: 2000});
            },
            (error) => {
              console.error('Erro ao cadastrar usuario:', error);
              this.apiService.getUsuario(this.phoneNumber).subscribe(
                (data) => {
                  this.handleSuccessfulResponse(data)
                },
                (error) => {
                  this.handleError(error)
                }
              )
            }
          );
      }
      else{
        cliente.phone = this.phoneNumber;
        this.pathUser();

      }
    }

  }

  //Acionado toda vez que uma letra é digitada no input do nome
  onInputChangeName(event:any)
  {
    const length = event.target.value.length
    if(length >= 3 )
    {
      this.lengthInputName = true;
    }
    else{
      this.lengthInputName = false;
    }

  }

  //Verifica se o numero esta realmente correto antes de  fazer qualquer cadastro
  correctNumberPhone(phone: string){
    let rawValue = ''
    let formatted = '';

    rawValue = phone.replace(/\D/g, '');

    if(rawValue != '')
    {
      const firstPart = rawValue.substring(0, 2)
      const secondPart = rawValue.substring(2, 7)
      const treetPart = rawValue.substring(7, 11)


      if(rawValue.length >= 1 && rawValue.length < 2){
        formatted = `(${firstPart}`
      }
      else if(rawValue.length >= 2 && rawValue.length < 7){
        formatted = `(${firstPart})`
      }
      else if(rawValue.length >= 7)
      {
        formatted = `(${firstPart})${secondPart}-${treetPart}`
      }

    }
    else{
      formatted = phone;
    }

    this.phoneNumber = formatted;
    return true;

  }

  //Chamada toda vez que é digitado um numero, se o numero telefone for maior igual a 14 ele faz os requests
  onInputChange(event: any){
    const newValue = event.target.value;

    if(newValue.length > 13)
    {
      this.lengthInputPhone = true;
    }
    else{
      this.lengthInputPhone = false;
    }

    let rawValue = ''
    let formatted = ''

    rawValue = newValue.replace(/\D/g, '');

    if(rawValue != '')
    {
      const firstPart = rawValue.substring(0, 2)
      const secondPart = rawValue.substring(2, 7)
      const treetPart = rawValue.substring(7, 11)


      if(rawValue.length >= 1 && rawValue.length < 2){
        formatted = `(${firstPart}`
      }
      else if(rawValue.length >= 2 && rawValue.length < 7){
        formatted = `(${firstPart})${secondPart}`
      }
      else if(rawValue.length >= 7)
      {
        formatted = `(${firstPart})${secondPart}-${treetPart}`
      }

    }
    else{
      this.name = '';
      this.lengthInputName = undefined;
      formatted = rawValue
    }

    this.phoneNumber = formatted;

    if(rawValue != '')
    {
      this.corretFormatPhoneNumber = this.phoneNumberPattern.test(this.phoneNumber)
    }
    else{
      this.corretFormatPhoneNumber = undefined
    }


    if(this.phoneNumber.length === 14){
      this.apiService.getUsuario(this.phoneNumber).subscribe(
        (data) => {
          this.handleSuccessfulResponse(data)
          this.alterationButton = true;
        },
        (error) => {
          console.log('Usuario não cadastrado');
          this.handleError(error)
          this.alterationButton = false;
        }
      )
    }
  }

  goback(): void{
    if(this.typeParam.type == 3)
    {
      this.router.navigate([this.nameCompany, 'B']);
    }
    else{
      this.router.navigate([this.nameCompany]);
    }
  }

  pathUser(){
    if(this.alterationUser){
      this.apiService.patchUser(this.id_customer, this.name, this.phoneNumber).subscribe(
        (data) =>{

          const userKey = {
            phone: data.response.phone,
            id_customer: data.response.id_customer,
            name: data.response.name
          }

          this.cacheService.setItemLocal('user_key', userKey);
          this.connection.updateUser(data.response);
        },
        (error) =>{
          console.log('Erro ao editar usuario:')
        })
    }
    else{
      const user = this.cacheService.getItemLocal('user_key');

      const userKey = {
        phone: this.existingUserData.response.customer.phone,
        id_customer: this.existingUserData.response.customer.id_customer,
        name: this.existingUserData.response.customer.name
      }

      if (user) {
        if (this.id_customer !== user.id_customer) {
          this.cacheService.removeItemLocal('userAddress');
          this.cacheService.setItemLocal('user_key', userKey);
        }
      } else {
        this.cacheService.setItemLocal('user_key', userKey);
      }

      this.connection.updateUser(this.existingUserData.response.customer);

    }
  }

  // Função para lidar com a resposta bem-sucedida
  private handleSuccessfulResponse(data: any): void {
    this.name = data.response.customer.name;
    this.id_customer = data.response.customer.id_customer;

    this.existingUser = true;
    this.lengthInputName = true;
    this.lengthInputPhone = true;

    this.existingUserData = data;

    this.inputPhone.nativeElement.blur();
  }

  // Função para lidar com erros
  private handleError(error: any): void {

    this.name = '';
    this.existingUser = false;
    if(this.name.length >= 3)
    {
      this.lengthInputName = true;
    }
    else{
      this.lengthInputName = false;
    }

    this.inputPhone.nativeElement.blur();
    this.inputName.nativeElement.focus();
  }

//#endregion

  disconnectUser(){
    this.openLogOut = false;
    this.cacheService.removeItemLocal('userAddress')
    this.cacheService.removeItemLocal('user_key');
    let clientEmpty!: Cliente;
    this.connection.updateUser(clientEmpty)
    this.user = clientEmpty
    this.userConfirmation = false;
    this.phoneNumber = '';
    this.name = '';
    this.corretFormatPhoneNumber = undefined;
    this.lengthInputName = undefined;
    this.lengthInputPhone = false;
  }

  navigateFidelity(){
    this.router.navigate([this.nameCompany, 'fidelity']);
  }

  navigateCoupons(){
    this.router.navigate([this.nameCompany, 'coupons']);
  }


}
