import { CacheService } from "../shared/services/Cache/cache.service";
import { paymentFormCompany } from "./company";
import { paymentForm } from "./order";
import { Produto } from "./produtos";


export interface Bag{
  products: Pedido[]
}

export interface OrderUser{
  id_companyFK: number,
  document_customer: string,
  id_customerFK: string;
  products: Pedido[];
  orderType: number;
  orderPrice: number;
  orderStatus: string;
  deliveryFee: number;
  changeFor: string;
  paymentForm: paymentForm[],
  orderObservation: string,
  id_address?: string,
  onlinePay?: number,
  localization?: string
  id_coupon: string;
  coupons: boolean,
  couponType: number,
  discountValue: number;
}

export interface OrderLocal{
  id_order: number;
  id_companyFK: number,
  id_table: string,
  displayId: string;
  products: Pedido[];
  tableNumber: number;
  cardNumber: number;
  orderType: number;
  orderPrice: number;
  orderStatus: string;
  onlinePay?: number;
  customerName?: string;
}

export interface cardTable{
  cardNumber: number;
  cardTable: number;
  id_companyFK: number;
}

export interface Pedido{
  name: string;
  id_product: number;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  pizzaPrice?: number;
  imagem_prod: string;
  observations: string;
  categories: categoryAdd[];
  baseValue: number;
  pizza?: number;
  flavorsValue?: number;
  productPromo?: boolean
  discountValue: number;
  discount: boolean;
  oldPrice: number
}

export interface categoryAdd{
  id_category:    string;
  categoryName:   string;
  additional:     additional[];
  division:       number;
  flavorCategory: number;
  edgeCategory:   number;
  required:       number;
  promotionPay?: boolean
  categoryLength: number;
}


export interface additional{
  add_id_product:   number;
  nameSub:          string;
  paymentAccept:   number;
  // edge:             number;
  quantitySub:      number;
  unitPriceSub:     number;
  totalPriceSub:    number;
  observationsSub:  string;
}


export class PedidoClass implements Pedido {

  name!: string;
  id_product!: number;
  quantity!: number;
  unitPrice!: number;
  totalPrice!: number;
  imagem_prod!: string;
  observations!: string;
  categories!: categoryAdd[]
  categoriesEdit!: categoryAdd[];
  pizzaPrice?: number;
  baseValue: number;
  valueTotal!: number;
  flavorsValue!: number;
  pizza!: number;
  baseFlavorsValue: number;
  discount!: boolean;
  discountValue!: number;
  oldPrice!: number

  constructor(produto: Produto, quantidade: number, observation: string, adicionais: categoryAdd[], baseValue: number, totalValue: number, pizzaPrice: number, flavorsValue: number, baseFlavorsValue: number) {
    this.name = produto.name;
    this.id_product = produto.id_product;
    this.quantity = quantidade;
    if(produto.pizza == 1){
      this.unitPrice = totalValue / quantidade;
    }
    else{
      this.unitPrice = produto.price;
    }

    this.totalPrice = totalValue
    this.imagem_prod = produto.image_prod;
    this.pizzaPrice = pizzaPrice;
    this.observations = observation;
    this.categories = adicionais
    this.baseValue = baseValue;
    this.flavorsValue = flavorsValue;
    this.pizza = produto.pizza;
    this.baseFlavorsValue = baseFlavorsValue;
    this.discount = false;
    this.discountValue = 0
    this.oldPrice = 0

  }




}
