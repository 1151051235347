import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Company, ConnectionService } from '../../services/Connection/connection.service';

@Component({
  selector: 'method-card',
  templateUrl: './method-card.component.html',
  styleUrls: ['../../../../assets/styles/global/_card.css', './method-card.component.css']
})
export class MethodCardComponent implements OnInit {
  step = false;
  company!: Company;
  @Input() methodReceipt: { checked: boolean, value: number } = {checked: false, value: 0}
  selectedOption: { checked: boolean, value: number } = { checked: false, value: 0};
  blockAlteration: boolean = false;
  @Output() optionSelected: EventEmitter<{ checked: boolean, value: number }> = new EventEmitter<{ checked: boolean, value: number }>();

  constructor(private connection: ConnectionService, private cdr: ChangeDetectorRef, private zone: NgZone){

  }

  ngOnInit(): void {
    this.connection.company$.subscribe((newCompany) =>{
      if(newCompany){
        this.company = newCompany

        if(this.company.configs.deliveryPay && !this.company.configs.onlinePay){
          this.onCheckboxChange({ checked: true, value: 0 });
          this.blockAlteration = true;
        }
        else if(!this.company.configs.deliveryPay && this.company.configs.onlinePay){
          this.onCheckboxChange({ checked: true, value: 1 })
          this.blockAlteration = true;
        }
        else{
          this.blockAlteration = false;
        }
      }

    })


  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.configVerification();
    });
  }

  ngOnDestroy(): void {
    if(this.selectedOption.checked == false && this.selectedOption.value == 0){
    }
  }

  configVerification(){
    this.step = true;
    // if(this.company.configs.onlineAccept == 0)
    // {
    //   const data = {checked: true, value: 0}
    //   this.onCheckboxChange(data)

    // }
    // if(this.selectedOption.checked == false)
    // {
    //   this.step = true;
    //   this.cdr.detectChanges();
    // }
  }

  onCheckboxChange(data: { checked: boolean, value: any }) {
    this.selectedOption = { checked: data.checked, value: data.value };
    this.optionSelected.emit({ checked: data.checked, value: data.value });
  }

  changeButton(){
    this.selectedOption.checked = false;
    this.selectedOption.value = 0
    this.optionSelected.emit({ checked: false, value: 0 });
  }
}
